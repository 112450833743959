import React from "react";

export const CATEGORIES = {
	eastman: [
		{
			name: "All Products",
			src: "/images/categories/eastman/2.jpg"
		}
	],

	jones: [
		{
			name: "Expanded PTFEatez Products",
			src: "/images/categories/jdjones/2.jpg"
		},
		{
			name: "Insulation",
			src: "/images/categories/jdjones/3.jpg"
		},
		{
			name: "Speciality Lubricant",
			src: "/images/categories/jdjones/4.jpg"
		},

		{
			name: "Low Emission Packing",
			src: "/images/categories/jdjones/5.jpg"
		}
	],

	praher: [
		{
			name: "Valves",
			src: "/images/categories/praher/1.png"
		},
		{
			name: "Pipe and Fittings",
			src: "/images/categories/praher/2.png"
		},
		{
			name: "Line Strainer",
			src: "/images/categories/praher/3.png"
		},
		{
			name: "Flow Meter",
			src: "/images/categories/praher/4.png"
		}
	],

	suez: [
		{
			name: "Boiler Water Treatment Chemicals",
			src: "/images/categories/suez/1.jpg"
		},
		{
			name: "Cooling Water Treatment",
			src: "/images/categories/suez/2.jpg"
		},
		{
			name: "Membrane Chemicals",
			src: "/images/categories/suez/3.jpg"
		},
		{
			name: "Wastewater Treatment Chemicals",
			src: "/images/categories/suez/4.jpg"
		},
		{
			name: "Industrial Process Treatments",
			src: "/images/categories/suez/5.jpg"
		},

		{
			name: "Dust Control and Material Handling",
			src: "/images/categories/suez/6.jpg"
		},

		{
			name: "Chemical Processing Treatment",
			src: "/images/categories/suez/7.jpg"
		},
		{
			name: "Hydrocarbon Processing Treatment",
			src: "/images/categories/suez/8.jpg"
		}
	],

	purewater: [
		{
			name: "test1",
			src: "/images/valve.png"
		},
		{
			name: "test1",
			src: "/images/valve.png"
		}
	]
};
