import React, { Component } from 'react';
import Header from '../../../common/Header.js';
import Footer from '../../../common/Footer.js';
import CategoryCard from '../../../common/CategoryCard.js';
import Sidemenu from '../../../common/Sidebar.js';
import '../../../assets/stylesheets/mainProducts.css';
import { CATEGORIES } from '../../../data/categories';

class Jones extends Component {
  render() {
    return (
      <div>
          <Header/>
          <div className="sectionHeader">
              <div className="headerSection">
                <hr className="line"></hr>
              </div>
              <div className="headerSectionMiddle">
                <text className="sectionTitle">J D JONES <b>PRODUCTS</b></text>
              </div>
              <div className="headerSection">
                <hr className="line"></hr>
              </div>
          </div>
          <div className="productContent">
          <Sidemenu open={false}/>
            {
              CATEGORIES.jones.map((category) => {
                return(
                  <CategoryCard
                    src={category.src}
                    name={category.name}
                    company='jd-jones'
                  />
                )
              })
            }
          </div>
          <Footer/>
      </div>
    );
  }
}

export default Jones;