import React, { Component } from "react";
import Header from "../../../common/Header.js";
import Footer from "../../../common/Footer.js";
import CategoryCard from "../../../common/CategoryCard.js";
import Sidemenu from "../../../common/Sidebar.js";
import "../../../assets/stylesheets/mainProducts.css";
import { DOCUMENTS } from "../../../data/suezDocuments.js";

class Documents extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="sectionHeader">
          <div className="headerSection">
            <hr className="line"></hr>
          </div>
          <div className="headerSectionMiddle">
            <text className="sectionTitle">
              SUEZ <b>PRODUCTS</b>
            </text>
          </div>
          <div className="headerSection">
            <hr className="line"></hr>
          </div>
        </div>
        <div className="productContent">
          <Sidemenu open={false} />
          {Object.keys(DOCUMENTS).map(inner => {
            return[
              <div className ="docLibraryTitle">{inner}</div>,
              <div>
                {DOCUMENTS[inner].map(document => {
                  return [
                    <form
                      method="get"
                      action={document.download}
                      class="downloadButton"
                      target="_blank"
                    >
                      <button className="docLibraryButton" style={{cursor: 'pointer'}}>
                      <img src={'/download-image.png'} width='60' height='60'></img>
                      <span>{document.name}</span>
                      </button>
                    </form>
                  ]
                })
                }
              </div>
            ]
          })}
        </div>
        <Footer />
      </div>
    );
  }
}

export default Documents;
