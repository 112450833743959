import React from "react";

export const DOCUMENTS = {
	'Boiler Water Treatment Chemicals - Feed Water Treatment Chemicals': [
		{
			name: "Cortrol-IS1050-03.06.2019",
			download: "documents/suez/1/Cortrol-IS1050-03.06.2019.pdf"
		},
		{
			name: "CorTrol IS1075 03.06.2019",
			download: "/documents/suez/1/CorTrol-IS1075-03.06.2019.pdf"
		},
		{
			name: "Cortrol IS3000 16.04.2019",
			download: "/documents/suez/1/Cortrol-IS3000-16.04.2019.pdf"
		},
		{
			name: "Cortrol OS5601 16.04.2019",
			download: "/documents/suez/1/Cortrol-OS5601-16.04.2019.pdf"
		},
		{
			name: "CorTrol OS5614 16.04.2019",
			download: "/documents/suez/1/CorTrol-OS5614-16.04.2019.pdf"
		},
		{
			name: "CorTrol OS7785 03.06.2019",
			download: "/documents/suez/1/CorTrol-OS7785-03.06.2019.pdf"
		},
		{
			name: "CorTrol OS9990 03.06.2019",
			download: "/documents/suez/1/CorTrol-OS9990-03.06.2019.pdf"
		},
		{
			name: "Optiguard MCP0021 16.04.2019",
			download: "/documents/suez/1/Optiguard-MCP0021-16.04.2019.pdf"
		}
	],
	'Boiler Water Treatment Chemicals - Internal Boiler Water Treatment Chemicals': [

		{
			name: "OptiSperse ADJ5050 15.04.2019",
			download: "/documents/suez/Boiler Water Treatment Chemicals - Internal Boiler Water Treatment Chemicals/OptiSperse-ADJ5050-15.04.2019.pdf"
		},
		{
			name: "OptiSperse HP3100",
			download: "/documents/suez/Boiler Water Treatment Chemicals - Internal Boiler Water Treatment Chemicals/OptiSperse-HP3100"
		},
		{
			name: "OptiSperse HP5433 16.04.2019",
			download: "/documents/suez/Boiler Water Treatment Chemicals - Internal Boiler Water Treatment Chemicals/OptiSperse-HP5433-16.04.2019.pdf"
		},
		{
			name: "OptiSperse HP5543 16.04.2019",
			download: "/documents/suez/Boiler Water Treatment Chemicals - Internal Boiler Water Treatment Chemicals/OptiSperse-HP5543-16.04.2019.pdf"
		},
		{
			name: "OptiSperse PO5068 03.06.2019",
			download: "/documents/suez/Boiler Water Treatment Chemicals - Internal Boiler Water Treatment Chemicals/OptiSperse-PO5068-03.06.2019.pdf"
		},
		{
			name: "OptiSperse SP8300 03.06.2019",
			download: "/documents/suez/Boiler Water Treatment Chemicals - Internal Boiler Water Treatment Chemicals/OptiSperse-SP8300-03.06.2019.pdf"
		}

	],
	'Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals': [
		{
			name: "CorrShield BT4301 03.06.2019.pdf",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/CorrShield-BT4301-03.06.2019.pdf"
		},
		{
			name: "CorrShield MD4100 16.04.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/CorrShield-MD4100-16.04.2019.pdf"
		},
		{
			name: "CorrShield-MD4153 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/CorrShield-MD4153-03.06.2019.pdf"
		},
		{
			name: "CorrShield NT420119",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/CorrShield-NT4201.pdf"
		},
		{
			name: "CorrShield NT4203 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/CorrShield-NT4203-03.06.2019.pdf"
		},
		{
			name: "CorrShield NT4293 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/CorrShield-NT4293-03.06.2019.pdf"
		},
		{
			name: "CorrShield OR4400 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/CorrShield-OR4400-03.06.2019.pdf"
		},
		{
			name: "FloGard MS6201 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/FloGard-MS6201-03.06.2019.pdf"
		},
		{
			name: "FloGard MS6209 16.14.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/FloGard-MS6209-16.14.2019.pdf"
		},
		{
			name: "Flogard MS6222 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/Flogard-MS6222-03.06.2019.pdf"
		},
		{
			name: "FloGard POT6101",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/FloGard-POT6101.pdf"
		},
		{
			name: "Inhibitor AZ8100 16.04.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/Inhibitor-AZ8100-16.04.2019.pdf"
		},
		{
			name: "Inhibitor AZ8104 16.04.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Corrosion Treatment Chemicals/Inhibitor-AZ8104-16.04.2019.pdf"
		}
	],
	'Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals': [
		{
			name: "DeposiTrol BL5301 03.06.2019",
			download: "documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/DeposiTrol-BL5301-03.06.2019.pdf"
		},
		{
			name: "DeposiTrol BL5323 03.06.2019",
			download: "documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/DeposiTrol-BL5323-03.06.2019.pdf"
		},
		{
			name: "DeposiTrol BL5400 03.06.2019",
			download: "documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/DeposiTrol-BL5400-03.06.2019.pdf"
		},
		{
			name: "DeposiTrol BL6501 16.04.2019",
			download: "documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/DeposiTrol-BL6501-16.04.2019.pdf"
		},
		{
			name: "GenGard GN7004 16.04.2019",
			download: "documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/GenGard-GN7004-16.04.2019.pdf"
		},
		{
			name: "GenGard GN7110 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/GenGard-GN7110-03.06.2019.pdf"
		},
		{
			name: "GenGard GN7114 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/GenGard-GN7114-03.06.2019.pdf"
		},
		{
			name: "GenGard GN8020 03.06",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/GenGard-GN8020-03.06.pdf"
		},
		{
			name: "GenGard GN8115 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/GenGard-GN8115-03.06.2019.pdf"
		},
		{
			name: "GenGard GN8115 03.06.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/GenGard-GN8203-03.06.2019.pdf"
		},
		{
			name: "ScaleTrol PDC9313 16.04.2019",
			download: "/documents/suez/Cooling Water Treatment Chemicals - Deposition And Scale Control Chemicals/ScaleTrol-PDC9313-16.04.2019.pdf"
		}
	],
	'Membrane Chemicals - Antiscalants': [
		{
			name: "HYPERSPERSE MDC 220",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/HYPERSPERSE-MDC-220.pdf"
		},
		{
			name: "HYPERSPERSE MDC 704",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/HYPERSPERSE-MDC-704.pdf"
		},
		{
			name: "HYPERSPERSE MDC 706",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/HYPERSPERSE-MDC-706.pdf"
		},
		{
			name: "Hypersperse MDC150",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/Hypersperse-MDC150.pdf"
		},
		{
			name: "Hypersperse MDC200",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/Hypersperse-MDC20.pdf"
		},
		{
			name: "Hypersperse MDC700 03.06.2019",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/Hypersperse-MDC700-03.06.2019.pdf"
		},
		{
			name: "Hypersperse MDC701 03.06.2019",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/Hypersperse-MDC701-03.06.2019.pdf"
		},
		{
			name: "HYPERSPERSE MSI 310",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/HYPERSPERSE-MSI-310.pdf"
		},
		{
			name: "HYPERSPERSE MSI 410",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/HYPERSPERSE-MSI-410.pdf"
		},
		{
			name: "AQUAMAX LT 19",
			download: "/documents/suez/Membrane Chemicals - Antiscalants/AQUAMAX-LT-19.pdf"
		}
	],

	'Membrane Chemicals - Cleaners': [
		{
			name: "KLEEN-MCT-103",
			download: "/documents/suez/Membrane Chemicals - Cleaners/KLEEN-MCT-103.pdf"
		},
		{
			name: "KLEEN-MCT-511",
			download: "/documents/suez/Membrane Chemicals - Cleaners/KLEEN-MCT-511.pdf"
		},
		{
			name: "KLEEN-MCT-515",
			download: "/documents/suez/Membrane Chemicals - Cleaners/KLEEN-MCT-515.pdf"
		},
		{
			name: "KLEEN-MCT-882",
			download: "/documents/suez/Membrane Chemicals - Cleaners/KLEEN-MCT-882.pdf"
		}
	],
	
	'Membrane Chemicals - Biocides': [
		{
			name: "BIOMATE-MBC-781.pdf",
			download: "/documents/suez/Membrane Chemicals - Biocides/BIOMATE-MBC-781.pdf"
		},
		{
			name: "BIOMATE-MBC-2881.pdf",
			download: "/documents/suez/Membrane Chemicals - Biocides/BIOMATE-MBC-2881.pdf"
		}
	]
}
