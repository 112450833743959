import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import "../assets/stylesheets/featureCard.css";
import valve from "../assets/images/valve.png";
import plus from "../assets/images/plus.svg";
import minus from "../assets/images/minus.svg";
import { Link } from "react-router-dom";
import {
  addToCart,
  removeFromCart
} from "../_Redux/ActionCreators/CartActionCreator";

class FeatureCard extends Component {
  state = {
    id: this.props.id,
    count: "0",
    modal: false
  };


  toggle = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  componentDidMount = () => {
    var products = this.props.products;
    for (var i = 0; i <= products.length - 1; i++) {
      if (products[i].id === this.props.id) {
        this.setState({ count: products[i].count.toString() });
      }
    }
  };

  addItem = (id, name, src) => {
    if (this.state.count === "0") {
      this.setState({ count: "1" });
    }

    this.props.addItem({ id: id, name: name, src: src });
    var products = this.props.products;

    for (var i = 0; i <= products.length - 1; i++) {
      if (products[i].id === this.props.id) {
        this.setState({ count: products[i].count.toString() });
      }
    }
  };

  removeItem = (id, name, src) => {
    var products = this.props.products;

    this.props.removeItem({ id: id, name: name, src: src });
    for (var i = 0; i <= products.length - 1; i++) {
      if (products[i].id === this.props.id) {
        this.setState({ count: products[i].count.toString() });
      }
    }
  };

  render() {
    return (
      <div className="cardBox">
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          style={{ height: "100vh" }}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>{this.props.name}</ModalHeader>
          <ModalBody className="modalBody">
            <div className="imageDiv">
              <img className="ModalImage" alt="logo" src={this.props.src}></img>
            </div>
            <div className="addToList">
              <span className="modalLightText">Add to Inquiry Cart</span>
              <div className="itemNumber modal">
                <button className="inquiryButton">
                  <img
                    className="icon"
                    alt="plus"
                    onClick={() => {
                      this.addItem(
                        this.props.id,
                        this.props.name,
                        this.props.src
                      );
                    }}
                    src={plus}
                  ></img>
                </button>
                <text className="number">{this.state.count}</text>
                {parseInt(this.state.count) > 0 && (
                  <button className="inquiryButton">
                    <img
                      className="icon"
                      alt="minus"
                      onClick={() => {
                        this.removeItem(
                          this.props.id,
                          this.props.name,
                          this.props.src
                        );
                      }}
                      src={minus}
                    ></img>
                  </button>
                )}
              </div>
            </div>
            <div className="contentDiv">
              <span className="modalSubTitle">Features</span>

              <ul className="feautureList">
                {this.props.features.map(feature => {
                  return <li>{feature}</li>;
                })}
              </ul>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.props.download !== "" && (
              <form
                method="get"
                target="_blank"
                action={this.props.download}
                class="downloadButton"
              >
                <Button color="primary" style={{cursor: 'pointer'}}>Download SPEC</Button>
              </form>
            )}
            <Button color="secondary" style={{cursor: 'pointer'}} onClick={this.toggle}>
              Done
            </Button>
          </ModalFooter>
        </Modal>
        <div className="cardImage">
          <img className="image" alt="logo" src={this.props.src}></img>
        </div>
        <div className="cardContent">
          <text className="text">{this.props.name}</text>
          <div className="buttons">
            <Link to='#' className="CTAtextbutton" onClick={(e)=>{this.toggle(e)}}>
              VIEW FEATURES
            </Link>
            {/*<Link to="#" className="CTAtext" onClick={ () => { this.addItem(this.props.id, this.props.name, this.props.src) } }>{'ADD TO INQUIRY LIST' + ' (' +  this.state.count + ')'}</Link>
            { this.state.count !== '0' &&
              <Link to="#" className="CTAtext" onClick={ () => { this.removeItem(this.props.id, this.props.name, this.props.src) } }>REMOVE FROM LIST</Link>
            }
          */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ products }) {
  return { products };
}

function mapDispatchToProps(dispatch) {
  return {
    addItem: item => {
      dispatch(addToCart(item));
    },
    removeItem: item => {
      dispatch(removeFromCart(item));
    }
  };
}

FeatureCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureCard);
export default FeatureCard;
