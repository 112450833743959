import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import Inquiry from "../components/Inquiry.js";
import "../assets/stylesheets/header.css";
import listicon from "../assets/images/list.png";
import hamburger from "../assets/images/menu.png";
import logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };

    this.toggleDiv = this.toggleDiv.bind(this);
  }

  toggleDiv = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  scrollToFooter = (e) => {

    e.preventDefault()
    console.log(document.getElementById("footer"));
    document.getElementById("footer").scrollIntoView();
  };

  render() {
    return (
      <div>
        <div className="headerContainer">
          <div className="divleft">
            <span>
              <img className="logo" alt="logo" src={logo}></img>
            </span>
          </div>
          <div className="divright">
            <div className="navFullsize">
              <span className="headerlinks">
                <Link to="/">Home</Link>
              </span>
              <span className="headerlinks">
                <Link to="/products">Products</Link>
              </span>
              <span className="headerlinks">
                <Link to="#" onClick={(e)=>{this.scrollToFooter(e)}}>
                  Contact us
                </Link>
              </span>
              <span className="inquirylink">
                <Link to="/inquiry">Inquiry list</Link>
              </span>
            </div>
            <div className="navSmall">
              <span className="inquirylink">
                {" "}
                <Link to="/inquiry">Inquiry list</Link>
              </span>
              <Link onClick={this.toggleDiv}>
                <img className="headericon" alt="logo" src={hamburger}></img>
              </Link>
            </div>
          </div>
        </div>
        {this.state.show && <Box />}
      </div>
    );
  }
}
class Box extends Component {
  scrollToFooter = () => {
    console.log(document.getElementById("footer"));
    document.getElementById("footer").scrollIntoView();
  };
  render() {
    return (
      <div className="responsiveoptions">
        <div className="option">
          <span>
            <Link to="/"> Home </Link>
          </span>
        </div>
        <div className="option">
          <span>
            <Link to="/products"> Products </Link>
          </span>
        </div>
        <div className="option">
          <span>
            <Link to="#" onClick={this.scrollToFooter}>
              {" "}
              Contact Us{" "}
            </Link>
          </span>
        </div>
      </div>
    );
  }
}

export default Header;
