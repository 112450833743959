import React from "react";

export const EASTMANPRODUCTS = {
	products: [
		{
			id: 1,
			name: "Therminol 55 Heat Transfer Fluid",
			src: "/images/products/eastman/1.png",
			features: [
				"Therminol 55 is a synthetic heat transfer fluid used in moderate temperature applications.",
				"Therminol 55 fluid is designed for use in non-pressurized /low-pressure, indirect heating systems. ",
				"It delivers efficient, dependable, uniform process heat with no need for high pressures."
			],
			download: "/documents/eastman/1.pdf"
		},
		{
			id: 2,
			name: "Therminol 59 Heat Transfer Fluid",
			src: "/images/products/eastman/2.jpg",
			features: [
				"Therminol 59 is a synthetic heat transfer fluid with excellent low temperature pumping characteristics and thermal stability."
			],
			download: "/documents/eastman/2.pdf"
		},
		{
			id: 3,
			name: "Therminol D12 Heat Transfer Fluid",
			src: "/images/products/eastman/3.jpg",
			features: [
				"Therminol D-12 is a synthetic liquid phase heat transfer fluid with excellent heat transfer properties over a wide temperature range.",
				"This fluid is ideally suited for applications requiring efficient cooling and heating."
			],
			download: "/documents/eastman/3.pdf"
		},
		{
			id: 4,
			name: "Therminol FF (Flush Fluid)",
			src: "/images/products/eastman/4.png",
			features: [
				"Therminol FF is the first synthetic flushing fluid for liquid phase heat transfer systems.",
				"Deposits from oxidized, thermally degraded or contaminated fluid can foul interior surfaces, making it necessary to clean the system prior to charging with new fluid."
			],
			download: "/documents/eastman/4.pdf"
		}
	]
};

export const JONESPRODUCTS = {
	Compression: [
		{
			id: 37,
			name: "Flax braided packing impregnated with petroleum jelly",
			src: "/images/products/jdjones/37.jpg",
			features: [
				"Packings ideal for rotary pump applications",
				"A solid plait packing specially finished yielding a soft readily comfortable material"
			],
			download: "/documents/jdjones/37.pdf"
		},
		{
			id: 38,
			name: "Acrylic + Spun Aramid Braided Packing",
			src: "/images/products/jdjones/38.jpg",
			features: [
				"A special purpose packing with abrasion resistance, thermal stability, flexibility and good chemical resistance",
				"Thorough impregnation of PTFE provides trouble free start-up and low friction operation",
				"A high density packing good for crystallising media"
			],
			download: "/documents/jdjones/38.pdf"
		},
		{
			id: 39,
			name: "PTFE + Expanded PTFE Graphited Braided Packing",
			src: "/images/products/jdjones/39.jpg",
			features: [
				"A true multi-service packing",
				"Excellent chemical resistance, heat dissipating, sliding velocity, low co-efficient of friction properties of PTFE graphite and abrasion, pressure resistance and mechanical strength of aramid",
				"Each expanded PTFE graphite yarn is encapsulated over aramid filament yarn",
				"A soft supple packing, non-abrasive in nature and gentle on the shaft"
			],
			download: "/documents/jdjones/39.pdf"
		},
		{
			id: 40,
			name: "PTFE Graphited + Aramid Core Braided Packing",
			src: "/images/products/jdjones/40.jpg",
			features: [
				"A true multi-service packing",
				"Excellent chemical resistance, heat dissipating, sliding velocity, low co-efficient of friction properties of PTFE graphite and the abrasion, pressure resistance and mechanical strength of carbon",
				"Each expanded PTFE graphite yarn is encapsulated over carbon filament yarn",
				"A soft supple packing, non-abrasive in nature and gentle on the shaft"
			],
			download: "/documents/jdjones/40.pdf"
		},
		{
			id: 41,
			name: "PTFE Graphited + Carbon Fibre Core Braided Packing",
			src: "/images/products/jdjones/41.jpg",
			features: [
				"A true multi-service packing",
				"Excellent chemical resistance, heat dissipating, sliding velocity, low co-efficient of friction properties of PTFE graphite and the abrasion, pressure resistance and mechanical strength of carbon",
				"Each expanded PTFE graphite yarn is encapsulated over carbon filament yarn",
				"A soft supple packing, non-abrasive in nature and gentle on the shaft"
			],
			download: "/documents/jdjones/41.pdf"
		},
		{
			id: 42,
			name: "Expanded PTFE Graphite + Glass fibre",
			src: "/images/products/jdjones/42.jpg",
			features: [
				"Packings ideal for rotary pump applications",
				"A solid plait packing specially finished yielding a soft readily comfortable material"
			],
			download: "/documents/jdjones/42.pdf"
		},
		{
			id: 43,
			name: "PTFE + Expanded PTFE Graphited Braided Packing",
			src: "/images/products/jdjones/43.jpg",
			features: [
				"A true multi-service packing",
				"Excellent chemical resistance, heat dissipating, sliding velocity, low coefficient of friction properties etc of PTFE graphite, with the abrasion, pressure resistance and mechanical strength of glass fibre",
				"Each expanded PTFE graphite yarn is encapsulated over glass fibre",
				"A soft, supple packing, non-abrasive in nature and gentle on the shaft"
			],
			download: "/documents/jdjones/43.pdf"
		},
		{
			id: 44,
			name:
				"Expanded Graphite + Carbon Fibre Core + Carbon Fibre Corner Braided Ring Set",
			src: "/images/products/jdjones/44.jpg",
			features: [
				"The most advanced technology cartridge set designed for the paper industry",
				"Carbon fibre having low co-efficient of friction enables it to run almost frictionless without any heat build up or fatigue"
			],
			download: "/documents/jdjones/44.pdf"
		},
		{
			id: 45,
			name: "Expanded PTFE Graphited + Carbon Fibre Braided Packing",
			src: "/images/products/jdjones/45.jpg",
			features: [
				"A unique construction packing specially designed for slurry application",
				"This unique construction ensures easy removal during repack resulting in reduced downtime",
				"Low friction, less heat generation, non-abrasive, saves shaft and shaft sleeve",
				"Widely used in the paper industry and mines"
			],
			download: "/documents/jdjones/45.pdf"
		},
		{
			id: 46,
			name:
				"Expanded Graphite + PTFE Graphited + Aramid Braided Ring Set",
			src: "/images/products/jdjones/46.jpg",
			features: [
				"An excellent solution to reduce sealing water in liquor circulation pump",
				"PTFE based end rings with a unique reinforcement inside and impregnation with a special anti-frictional, temperature resistance compound enable the packing set to exhibit excellent chemical resistance, heat dissipating, sliding velocity, low co-efficient of friction properties etc, at the same time, the abrasion, pressure resistance and mechanical strength",
				"Special intermediate ring to run ‘dry’",
				"A soft supple packing, non-abrasive in nature and gentle on the shaft"
			],
			download: "/documents/jdjones/46.pdf"
		},
		{
			id: 47,
			name: "Copper Wire Braided Packing",
			src: "/images/products/jdjones/47.jpg",
			features: [
				"Braided from soft annealed copper",
				"Designed for use as a bull ring or anti-extrusion ring"
			],
			download: "/documents/jdjones/47.pdf"
		},
		{
			id: 48,
			name: "Expanded PTFE Braided Packing for Plunger Pump Application",
			src: "/images/products/jdjones/48.jpg",
			features: [
				"A superb choice for rotary as well as static equipment packing needs",
				"Made from expanded PTFE graphite yarns having excellent chemical resistance and heat dissipation properties",
				"The packing is proven over a wide range of applications including feed water, harsh chemicals, caustics, oils, mild slurries etc",
				"Excellent dimensional stability",
				"Does not burn on start-up, low co-efficient of friction"
			],
			download: "/documents/jdjones/48.pdf"
		},
		{
			id: 49,
			name: "Expanded Graphite + Meta Aramid + PTFE Dispersion",
			src: "/images/products/jdjones/49.jpg",
			features: [
				"Ideal packing for services ensuring strength and resistance to corner extrusion",
				"Excellent abrasion resistance with high thermal conductivity"
			],
			download: "/documents/jdjones/49.pdf"
		},
		{
			id: 50,
			name: "Glass Fibre Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/50.jpg",
			features: [
				"A universal packing made from high quality glass fibre yarn impregnated with PTFE and lubricant",
				"Very low co-efficient of friction, high thermal conductivity and sliding velocity, runs almost frictionless without any shaft wear",
				"Excellent resistance to chemicals and hardening thereof"
			],
			download: "/documents/jdjones/50.pdf"
		},
		{
			id: 51,
			name:
				"Glass Fibre Braided Packing + PTFE Dispersion + Graphite + Oil",
			src: "/images/products/jdjones/51.jpg",
			features: [
				"A universal packing made from high quality glass fibre yarn impregnated with PTFE and lubricant",
				"Very low co-efficient of friction, high thermal conductivity and sliding velocity , runs almost frictionless without any shaft wear",
				"Graphite impregnation improves chemical resistance and also acts as blocking agent",
				"Excellent resistance to chemicals and hardening thereof"
			],
			download: "/documents/jdjones/51.pdf"
		},
		{
			id: 52,
			name: "Expanded Graphite + Inconel Wire Braided Packing",
			src: "/images/products/jdjones/52.jpg",
			features: [
				"Ideal for superheated and saturated steam, hydrocarbons and chemicals valves",
				"Incorporated with a sacrificial metal corrosion inhibitor to protect the shaft from galvanic corrosion",
				"Passed fugitive emissions tests as per shell SPE MESC 77/312 (as top and bottom rings with NA 707 packing), Class B",
				"Approved and standardised by leading valve makers as well as end users across the globe"
			],
			download: "/documents/jdjones/52.pdf"
		},
		{
			id: 53,
			name:
				"Graphite Combination Packing Set of Braided End Ring and Moulded Intermediate Rings",
			src: "/images/products/jdjones/53.jpg",
			features: [
				"Top and bottom anti-extrusion rings of braided flexible graphite with Inconel wire (single oblique cut)",
				"Intermediate rings of die moulded graphite (endless/2 halves",
				"Ideal for frequently operated control valves handling superheated and saturated steam, hydrocarbons and chemicals valves",
				"The set has superb resilience"
			],
			download: "/documents/jdjones/53.pdf"
		},
		{
			id: 54,
			name:
				"Expanded Graphite + Inconel Wire Braided Packing + Self Adhesive Backing",
			src: "/images/products/jdjones/54.jpg",
			features: [
				"Versatile gasket material for wide range of application",
				"Ideal for superheated and saturated steam, hydrocarbons and chemicals",
				"Incorporated with a sacrificial metal corrosion inhibitor to protect from galvanic corrosion",
				"Can be used throughout plant because of easiness on installation, can be made to any dimension and shape and high thermal and chemical resistance"
			],
			download: "/documents/jdjones/54.pdf"
		},
		{
			id: 55,
			name: "Expanded Graphite Braided Packing",
			src: "/images/products/jdjones/55.jpg",
			features: [
				"Low cost expanded pure graphite packing",
				"Dissipates heat without chemical hardening, resistant to practically all chemicals",
				"Dense pliable packing with a high degree of resiliency",
				"Does not cause shaft wear",
				"Reduces flush water consumption"
			],
			download: "/documents/jdjones/55.pdf"
		},
		{
			id: 56,
			name: "Expanded Graphite + Carbon Fibre Reinforced Braided Packing",
			src: "/images/products/jdjones/56.jpg",
			features: [
				"An excellent expanded pure graphite packing with carbon yarn reinforcement as well as carbon yarn inside the braided construction",
				"Dissipates heat without chemical hardening, resistant to practically all chemicals",
				"Withstands higher shaft speeds while limiting friction and also prevents extrusion",
				"Does not cause shaft wear"
			],
			download: "/documents/jdjones/56.pdf"
		},
		{
			id: 57,
			name: "Expanded Graphite + Aramid Core Braided Packing",
			src: "/images/products/jdjones/57.jpg",
			features: [
				"One of the most versatile packings for plant wide pump and valve use",
				"The unique structure of the packing, made from expanded graphite yarns having a core of high grade aramid filament, make the packing one of the best performers for pumping applications",
				"Fantastic dry running capabilities—the packing is almost flushless",
				"Proven for regular as well as aggressive fluid services, including FCC catalyst, cooling water, pulp etc",
				"A corrosion inhibitor prevents stem pitting"
			],
			download: "/documents/jdjones/57.pdf"
		},
		{
			id: 58,
			name: "Expanded Graphite Braided Packing + PTFE Dispersion",
			src: "/images/products/jdjones/58.jpg",
			features: [
				"Low cost expanded pure graphite packing",
				"Dissipates heat without chemical hardening, resistant to practically all chemicals",
				"Dense, pliable packing with a high degree of resiliency",
				"Does not cause shaft wear",
				"Reduces flush water consumption"
			],
			download: "/documents/jdjones/58.pdf"
		},
		{
			id: 59,
			name: "Expanded Graphite + Carbon Fibre Corner Braided Packing",
			src: "/images/products/jdjones/59.jpg",
			features: [
				"A true multipurpose packing for a wide range of applications",
				"High grade carbon yarns criss-cross the packing to emerge at the corners, providing mechanical strength and extrusion resistance to the packing",
				"Excellent dry running capabilities reduce plant wide flush water use",
				"A corrosion inhibitor prevents stem pitting",
				"The packing fills into worn out surfaces and pittings, thus cutting out leak paths.",
				"Dissipates heat without chemical hardening, resistant to chemicals"
			],
			download: "/documents/jdjones/59.pdf"
		},
		{
			id: 60,
			name:
				"Expanded Graphite + Carbon Fibre Core + Carbon Fibre Corner Braided Packing",
			src: "/images/products/jdjones/60.jpg",
			features: [
				"One of the most versatile packings for plant wide pump and valve use",
				"The unique structure of the packing, made from expanded graphite yarns having a core of high grade carbon yarns, further reinforced with outside corners of carbon yarns, make the packing one of the best performers for pumping applications",
				"Fantastic dry running capabilities—the packing is almost ‘flushless",
				"Proven for regular as well as aggressive fluid services, including FCC catalyst, cooling water, pulp etc",
				"A corrosion inhibitor prevents stem pitting"
			],
			download: "/documents/jdjones/60.pdf"
		},
		{
			id: 61,
			name: "Expanded Graphite + Carbon Fibre Core Braided Packing",
			src: "/images/products/jdjones/61.jpg",
			features: [
				"One of the most versatile packings for plant wide pump and valve use",
				"The unique structure of the packing, made from expanded graphite yarns having a core of high grade carbon yarns, make the packing one of the best performers for pumping applications",
				"Fantastic dry running capabilities—the packing is almost ‘flushless",
				"Proven for regular as well as aggressive fluid services, including FCC catalyst, cooling water, pulp etc",
				"A corrosion inhibitor prevents stem pitting"
			],
			download: "/documents/jdjones/61.pdf"
		},
		{
			id: 62,
			name:
				"Expanded Graphite + Inconel Wire Reinforced + Carbon Fibre Jacketed Braided Packing",
			src: "/images/products/jdjones/62.jpg",
			features: [
				"One of the best sealing solutions for all types of high temperature-high pressure valves, specially control valves",
				"The core consists of expanded graphite yarns, each covered with an Inconel wire mesh and also reinforced with multiple Inconel wires inside",
				"The core is overbraided with high strength-low friction carbon yarns",
				"The packing is finally finished with corrosion inhibitors, graphite and blocking agents",
				"A rugged packing that does not disintegrate on cutting, provides a leak free performance and can be removed with ease"
			],
			download: "/documents/jdjones/62.pdf"
		},
		{
			id: 63,
			name: "Expanded Graphite Braided Packing + Carbon Fibre",
			src: "/images/products/jdjones/63.jpg",
			features: [
				"One of the most versatile packings for plant wide pump and valve use",
				"The unique structure of the packing, made from expanded graphite yarns having a core of high grade carbon yarns, make it one of the best performers for pumping applications",
				"Fantastic dry running capabilities—the packing is almost flushless",
				"Proven for regular as well as aggressive fluid services, including FCC catalyst, cooling water, pulp etc"
			],
			download: "/documents/jdjones/63.pdf"
		},
		{
			id: 64,
			name: "Acrylic Fibre Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/64.jpg",
			features: [
				"A general purpose packing with good chemical resistance and high tensile strength",
				"Thorough impregnation of PTFE provides trouble free start-up and low friction operation",
				"A high density packing good for crystallising media",
				"A filament core provides mechanical strength and dimensional stability",
				"Widely used for knife gate valves"
			],
			download: "/documents/jdjones/64.pdf"
		},
		{
			id: 65,
			name:
				"Acrylic Fibre Braided Packing + PTFE Dispersion + Oil + Rubber Core",
			src: "/images/products/jdjones/65.jpg",
			features: [
				"A general purpose packing with good chemical resistance and high tensile strength",
				"Thorough impregnation of PTFE provides trouble free start-up and low friction operation",
				"A high density packing good for crystallising media",
				"A filament core provides mechanical strength and dimensional stability",
				"Widely used for knife gate valves"
			],
			download: "/documents/jdjones/65.pdf"
		},
		{
			id: 66,
			name: "Acrylic Fibre Braided Packing + PTFE Dispersion",
			src: "/images/products/jdjones/66.jpg",
			features: [
				"A general purpose packing with good chemical resistance and high tensile strength",
				"Thorough impregnation of PTFE provides trouble free start-up and low friction operation",
				"A high density packing good for crystallising media",
				"A filament core provides mechanical strength and dimensional stability"
			],
			download: "/documents/jdjones/66.pdf"
		},
		{
			id: 67,
			name: "Acrylic + PTFE Dispersion + Copper Corner Braided Packing",
			src: "/images/products/jdjones/67.jpg",
			features: [
				"Latest development for knife gate valve industry replacing conventional packing set of acrylic fibre or PTFE braided packing with copper wire braided anti-extrusion ring at top and bottom",
				"The unique structure of the packing makes it ideal in terms of heavy duty corrosion and abrasion resistance for the toughest liquid slurry and dry material handling applications",
				"Copper wire on the corners eliminates distortion under maximum differential pressure while an acrylic fibre PTFE impregnated face ensures tight sealing",
				"Copper, being very soft and ductile, reduces wear and tear"
			],
			download: ""
		},
		{
			id: 68,
			name:
				"Acrylic Fibre Braided Packing + PTFE Dispersion + Graphite + Oil",
			src: "/images/products/jdjones/68.jpg",
			features: [
				"A general purpose packing with good chemical resistance and high tensile strength",
				"Thorough impregnation of graphite enhances the chemical resistance and effective sealing provides trouble free start-up and low friction operation"
			],
			download: ""
		},
		{
			id: 69,
			name:
				"Acrylic Fibre Braided Packing + Para Aramid Fibre + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/69.jpg",
			features: [
				"A special purpose packing for slurries with excellent elastic recovery properties",
				"Para aramid fibre on the corners enhances strength and chemical resistance",
				"Thorough impregnation of PTFE provides trouble free startup and low friction operation",
				"Easy to fit, easy to cut, doesn’t heat up much"
			],
			download: ""
		},
		{
			id: 70,
			name:
				"Acrylic Fibre Braided Packing + Aramid Fibre + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/70.jpg",
			features: [
				"A special purpose packing for slurries with excellent elastic recovery properties",
				"Aramid fibre on the corners enhances strength and chemical resistance",
				"Thorough impregnation of PTFE provides trouble free startup and low friction operation",
				"Easy to fit, easy to cut, doesn’t heat up much"
			],
			download: ""
		},
		{
			id: 71,
			name:
				"Acrylic Fibre + Kynol Corner Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/71.jpg",
			features: [
				"A special purpose packing for slurries with excellent elastic recovery property",
				"Kynol fibre on the corners enhances strength and chemical resistance",
				"Carbon fibre core enhances recovery property, thus ensures little tightening after installation and optimization maintenance time",
				"Thorough impregnation of PTFE provides trouble free start-up and low friction operation",
				"Easy to fit, easy to cut, doesn’t heat up much"
			],
			download: ""
		},
		{
			id: 72,
			name:
				"Acrylic Fibre Braided Packing + Kynol Fibre + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/72.jpg",
			features: [
				"A special purpose packing for slurries with excellent elastic recovery properties",
				"Kynol fibre on the corners enhances strength and chemical resistance",
				"Thorough impregnation of PTFE provides trouble free startup and low friction operation",
				"Easy to fit, easy to cut, doesn’t heat up much"
			],
			download: ""
		},
		{
			id: 73,
			name: "PTFE Braided Packing + Lubricant",
			src: "/images/products/jdjones/73.jpg",
			features: [
				"Excellent packing for all chemicals, including acids and alkali of any concentration",
				"Interlock braid construction gives high structural strength and dimensional stability to the packing",
				"Impregnation of break-in lubricant reduces start up friction, improves running and lubricating properties, helps resist migrations and cuts out all leak paths in the packing",
				"A clean non contaminating packing with low co-efficient of friction"
			],
			download: ""
		},
		{
			id: 74,
			name: "Spun Aramid Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/74.jpg",
			features: [
				"The fibrous nature of aramid allows a thorough impregnation of PTFE in each yarn and then in the final packing to give low friction and trouble free operations",
				"A dense, non-contaminating packing with excellent volume stability—requires less frequent gland adjustments",
				"Superb abrasion resistance without any harm to the parent equipment",
				"Can be used for soft shaft/sleeve constructions",
				"Food Grade available"
			],
			download: ""
		},
		{
			id: 75,
			name: "Meta Aramid Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/75.jpg",
			features: [
				"The fibrous nature of Meta Aramid allows a thorough impregnation of PTFE in each yarn and then in the final packing to give low friction and trouble free operations",
				"A dense non-contaminating packing with excellent volume stability—requires less frequent gland adjustments",
				"Superb abrasion resistance without any harm to the parent equipment",
				"Can be used for soft shaft/sleeve constructions",
				"Food Grade available"
			],
			download: ""
		},
		{
			id: 76,
			name: "Preox Spun Aramid Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/76.jpg",
			features: [
				"The fibrous nature of aramid staple fibre allows a thorough impregnation of PTFE in each yarn and then in the final packing to give low friction and trouble free operations",
				"Blending with pre-oxidised increases temperature resistance together with high mechanical stability and low co-efficient of friction",
				"A dense non contaminating packing with excellent volume stability—requires less frequent gland adjustments",
				"Superb abrasion resistance without any harm to the parent equipment"
			],
			download: ""
		},
		{
			id: 77,
			name:
				"Spun Aramid Braided Packing + PTFE Dispersion + Oil + Graphite",
			src: "/images/products/jdjones/77.jpg",
			features: [
				"The fibrous nature of aramid allows a thorough impregnation of PTFE in each yarn and then in the final packing to give low friction and trouble free operations",
				"A dense non-contaminating packing with excellent volume stability, requires less frequent gland adjustments",
				"Superb abrasion resistance without any harm to the parent equipment",
				"Can be used for soft shaft/sleeve constructions",
				"Addition of graphite improves heat dissipating properties"
			],
			download: ""
		},
		{
			id: 78,
			name: "PTFE + Aramid Braided Packing",
			src: "/images/products/jdjones/78.jpg",
			features: [
				"The PTFE fibres yarns at the base provide excellent chemical resistance, low co-efficient of friction and virtually eliminate shaft scoring",
				"The tough PTFE impregnated aramid fibre yarns at the corners provides superb extrusion, abrasion and pressure resistance, allowing the packing to be used at very high pressure conditions",
				"Clean and non-toxic, can be used over a wide range of media including critical services that demand an extrusion resistant or reinforced packing"
			],
			download: ""
		},
		{
			id: 79,
			name: "Aramid Fibre + PTFE Fibre Braided Packing",
			src: "/images/products/jdjones/79.jpg",
			features: [
				"The PTFE fibres yarns at the base provide excellent chemical resistance, low co-efficient of friction and virtually eliminate shaft scoring",
				"The tough PTFE impregnated aramid fibre yarns at the corners provides superb extrusion, abrasion and pressure resistance, allowing the packing to be used at very high pressure conditions",
				"Clean and non-toxic, can be used over a wide range of media including critical services that demand an extrusion resistant or reinforced packing",
				"Available in prepressed ring form."
			],
			download: ""
		},
		{
			id: 80,
			name: "PTFE + Aramid Braided Packing",
			src: "/images/products/jdjones/80.jpg",
			features: [
				"The PTFE fibres yarns provide excellent chemical resistance, low co-efficient of friction and virtually eliminate shaft scoring",
				"The tough PTFE impregnated aramid fibre yarns are pressure resistance, allowing the packing to be used at very high speed with minimal shaft wear"
			],
			download: ""
		},
		{
			id: 81,
			name: "Aramid braided packing + PTFE dispersion + Oil",
			src: "/images/products/jdjones/81.jpg",
			features: [
				"One of the best sealing solutions for abrasive media such as stock, juice (sugar), slurries, fly ash, ash slurry etc",
				"High degree of resiliency and consistency of volume",
				"Special PTFE impregnation process to avoid shaft erosion and start up friction",
				"Interlock braiding for high pressure and extrusion resistance",
				"Shaft/sleeve hardness should be > 45 HRC: If not, please consult us",
				"Food Grade available"
			],
			download: ""
		},
		{
			id: 82,
			name: "Aramid braided packing + PTFE dispersion + Oil (Food Grade)",
			src: "/images/products/jdjones/82.jpg",
			features: [
				"One of the best sealing solutions for abrasive media such as stock, juice (sugar), slurries, fly ash, ash slurry etc",
				"High degree of resiliency and consistency of volume",
				"Special PTFE impregnation process to avoid shaft erosion and start up friction",
				"Interlock braiding for high pressure and extrusion resistance",
				"Shaft/sleeve hardness should be > 45 HRC: If not, please consult us",
				"Food Grade certified as per Commission Regulation (EU) No 10/2011 of 14 January 2011 and Article 3 of European Regulation No. 1935/2004"
			],
			download: "/documents/jdjones/82.pdf"
		},
		{
			id: 83,
			name: "Aramid Braided Packing + PTFE Dispersion + Oil + Graphite",
			src: "/images/products/jdjones/83.jpg",
			features: [
				"One of the best sealing solutions for abrasive media such as stock, juice (sugar), slurries, fly ash, ash slurry etc",
				"High degree of resiliency and consistency of volume",
				"Special PTFE impregnation process to avoid shaft erosion and start-up friction",
				"Interlock braiding for high pressure and extrusion resistance",
				"Shaft/sleeve hardness should be > 45 HRC; if not, please consult us",
				"Addition of graphite improves heat dissipating properties"
			],
			download: "/documents/jdjones/83.pdf"
		},
		{
			id: 84,
			name: "Carbon Fibre Braided Packing + Graphite Dispersion",
			src: "/images/products/jdjones/84.jpg",
			features: [
				"Low co-efficient of friction enables it to run almost frictionless without any heat build up or fatigue",
				"The graphite mix impregnation acts as a blocking agent, stopping all gas/liquid penetrations; at the same time, it is high temperature resistant",
				"Excellent resistance to chemicals",
				"High mechanical strength and dimensional stability",
				"Corrosion inhibitor present in the graphite mix prevents stem pitting",
				"Can be used as an anti-extrusion rings or bull ring in combination with softer packings, even in abrasive media"
			],
			download: "/documents/jdjones/84.pdf"
		},
		{
			id: 85,
			name:
				"Carbon Fibre Braided Packing + Graphite Mix + Solid Lubricant",
			src: "/images/products/jdjones/85.jpg",
			features: [
				"Low co-efficient of friction enables it to run almost frictionless without any heat build up or fatigue",
				"The graphite mix impregnation acts as a blocking agent, stopping all gas/liquid penetrations, while also being resistant to high temperatures",
				"Excellent resistance to chemicals",
				"High mechanical strength and dimensional stability",
				"Corrosion inhibitor present in the graphite mix prevents stem pitting",
				"Can be used as an anti-extrusion ring or bull ring in combination with softer packings, even in abrasive media"
			],
			download: "/documents/jdjones/85.pdf"
		},
		{
			id: 86,
			name:
				"Carbon Fibre Braided Packing + Graphite Dispersion + Rubber Core",
			src: "/images/products/jdjones/86.jpg",
			features: [
				"Low coefficient of friction, enables it to run almost frictionless without any heat build up or fatigue.",
				"The graphite mix impregnation acts as a blocking agent, stopping all gas/liquid penetrations, while also being resistant to high temperatures",
				"Excellent resistance to chemicals",
				"High mechanical strength and dimensional stability.",
				"Corrosion inhibitor present in the graphite mix prevents stem pitting",
				"Can be used as an anti-extrusion rings or bull ring in combination with softer packings, even in abrasive media",
				"Hollow silicon rubber core provides superb recovery and resilience enabling the packing to withstand shaft deflection/vibration with ease."
			],
			download: "/documents/jdjones/86.pdf"
		},
		{
			id: 87,
			name:
				"Carbon Fibre Braided Packing + Graphite Dispersion + Inconel Wire",
			src: "/images/products/jdjones/87.jpg",
			features: [
				"Low co-efficient of friction enables it to run almost frictionless without any heat build up or fatigue",
				"The graphite mix impregnation acts as a blocking agent, stopping all gas/liquid penetrations; at the same time it is high temperature resistant",
				"Excellent resistance to chemicals",
				"High mechanical strength and dimensional stability",
				"Corrosion inhibitor present in the graphite mix prevents stem pitting",
				"Can be used as anti-extrusion rings or a bull ring in combination with softer packings, even in abrasive media"
			],
			download: "/documents/jdjones/87.pdf"
		},
		{
			id: 88,
			name: "Carbon Fibre Braided Packing + PTFE Dispersion",
			src: "/images/products/jdjones/88.jpg",
			features: [
				"A non-staining and non-contaminating carbon fibre packing with good running properties",
				"A low friction packing with excellent chemical resistance, even against strong acids and caustics",
				"Impregnation of PTFE and break-in lubricant reduces start-up friction, improves sliding properties and adds to the chemical resistance of the packing",
				"Wide application in paper mills and refineries"
			],
			download: "/documents/jdjones/88.pdf"
		},
		{
			id: 89,
			name: "Graphite Fibre Braided Packing + Graphite Dispersion",
			src: "/images/products/jdjones/89.jpg",
			features: [
				"A universal plant wide multi-service packing",
				"Very low co-efficient of friction, high thermal conductivity and sliding velocity, runs almost frictionless without any shaft wear",
				"Excellent resistance to chemicals and hardening thereof",
				"Structural strength and cross-sectional density of the packing helps reduce maintenance work",
				"Can be used as an anti-extrusion rings or bull ring in combination with softer packings, even in abrasive media"
			],
			download: "/documents/jdjones/89.pdf"
		},
		{
			id: 90,
			name: "Graphite Fibre Braided Packing + Graphite Dispersion + Oil",
			src: "/images/products/jdjones/90.jpg",
			features: [
				"A universal plant wide multi-service packing made from high quality graphite fibres impregnated with a special graphite mix and special lubricant",
				"Very low co-efficient of friction, high thermal conductivity and sliding velocity, runs almost frictionless without any shaft wear",
				"Use of additional lubricant improves overall performance in dynamic application, also acts as a blocking agent",
				"Excellent resistance to chemicals and hardening thereof",
				"Structural strength and cross-sectional density of the packing helps reduce maintenance work"
			],
			download: ""
		},
		{
			id: 91,
			name:
				"Graphite Braided Packing + Graphite Dispersion + Inconel Wire",
			src: "/images/products/jdjones/91.jpg",
			features: [
				"A universal plant wide multi-service packing",
				"Very low co-efficient of friction, high thermal conductivity and sliding velocity, runs almost frictionless without any shaft wear",
				"Excellent resistance to chemicals and hardening thereof",
				"Structural strength and cross-sectional density of the packing helps reduce maintenance work",
				"Can be used as an anti-extrusion rings or bull ring in combination with softer packings, even in abrasive media"
			],
			download: "/documents/jdjones/91.pdf"
		},
		{
			id: 92,
			name: "PTFE Graphited Braided Packing",
			src: "/images/products/jdjones/92.jpg",
			features: [
				"A general purpose packing made from high grade graphited and lubricated PTFE yarns",
				"Can be used for a wide range of services across the plant",
				"Dense pliable packing with a high degree of resiliency",
				"Does not cause shaft wear"
			],
			download: "/documents/jdjones/92.pdf"
		},
		{
			id: 93,
			name:
				"PTFE Graphited + Carbon Core + Carbon Corner Braided Packing",
			src: "/images/products/jdjones/93.jpg",
			features: [
				"One of the most versatile pump packings for all slurry applications",
				"The unique structure of the packing, made from expanded PTFE graphite yarn ensures non abrasion, saves shafts and sleeves. Structural carbon fibre reinforcement ensures rigidity while carbon fibre on the outside corner offers a high degree of slurry sealing and long leakage",
				"Easily removable, ensuring low downtime"
			],
			download: "/documents/jdjones/93.pdf"
		},
		{
			id: 94,
			name: "Expanded PTFE Graphited Braided Packing",
			src: "/images/products/jdjones/94.jpg",
			features: [
				"A superb choice of rotary as well as static equipment packing needs",
				"Made from expanded PTFE graphite yarns having excellent chemical resistance and heat dissipation properties",
				"The packing is proven over a wide range of applications including feed water, harsh chemicals, caustics, oils, mild slurries etc",
				"Excellent dimensional stability",
				"Does not burn on start-up, low co-efficient of friction"
			],
			download: "/documents/jdjones/94.pdf"
		},
		{
			id: 95,
			name: "Expanded PTFE Graphited (G-2 Fibre) Braided Packing",
			src: "/images/products/jdjones/95.jpg",
			features: [
				"A superb choice of rotary as well as static equipment packing needs",
				"Made from expanded PTFE graphite yarns having excellent chemical resistance and heat dissipation properties",
				"The packing is proven over a wide range of applications including feed water, harsh chemicals, caustics, oils, mild slurries etc",
				"Excellent dimensional stability",
				"Does not burn on start-up, low co-efficient of friction"
			],
			download: "/documents/jdjones/95.pdf"
		},
		{
			id: 96,
			name: "Expanded PTFE Braided Packing",
			src: "/images/products/jdjones/96.jpg",
			features: [
				"Excellent heat dissipation properties, similar to graphite, allows the packing to withstand higher shaft speeds than conventional PTFE packings",
				"Ideal for bleach washer, chlorine services, corrosive media in a paper mill",
				"High lubricity and thermal conductivity of the packing virtually eliminates shaft scoring, extending the life of the packing and the pump",
				"Superb resistance to practically all chemical media",
				"Does not swell, unlike conventional pure PTFE packing"
			],
			download: "/documents/jdjones/96.pdf"
		},
		{
			id: 97,
			name: "Expanded Pure PTFE Braided Packing, Food Grade",
			src: "/images/products/jdjones/97.jpg",
			features: [
				"Excellent heat dissipation properties, similar to graphite, allow the packing to withstand higher shaft speeds than conventional PTFE packings",
				"Ideal for bleach washer, chlorine services, corrosive media in paper mills",
				"Superb resistance to practically all chemical media",
				"Does not swell, unlike conventional pure PTFE packing"
			],
			download: "/documents/jdjones/97.pdf"
		},
		{
			id: 98,
			name: "Aramid Fibre + PTFE Fibre Braided Rope",
			src: "/images/products/jdjones/98.jpg",
			features: [
				"The expanded pre-lubed PTFE fibres yarns at the base provide excellent chemical resistance, low co-efficient of friction and virtually eliminate shaft scoring.",
				"The tough PTFE impregnated aramid fibre yarns at the corners provides superb extrusion, abrasion and pressure resistance, allowing the packing to be used at very high pressure conditions.",
				"Clean and non-toxic, can be used over a wide range of media including critical services that demand an extrusion resistant or reinforced packing."
			],
			download: ""
		},
		{
			id: 99,
			name: "Expanded PTFE Graphited + Aramid Braided Packing",
			src: "/images/products/jdjones/99.jpg",
			features: [
				"Ideal packing for services where chemical resistance, high strength and sliding properties are essential",
				"The special aramid fibres at the corners enhance pressure and extrusion resistance of the packing even at higher temperatures",
				"Excellent resistance to abrasive media, low friction packing",
				"Can be used in all aggregates with a large clearance/gap"
			],
			download: ""
		},
		{
			id: 100,
			name:
				"Expanded PTFE Graphited (G-2 Fibre) + Aramid Braided Packing",
			src: "/images/products/jdjones/100.jpg",
			features: [
				"Ideal packing for services where chemical resistance, high strength and sliding properties are essential",
				"The special aramid fibres at the corners enhance pressure and extrusion resistance of the packing even at higher temperatures",
				"Excellent resistance to abrasive media, low friction packing",
				"Can be used in all aggregates with a large clearance/gap"
			],
			download: ""
		},
		{
			id: 101,
			name: "PTFE Graphited + Aramid Braided Packing + Rubber Core",
			src: "/images/products/jdjones/101.jpg",
			features: [
				"Ideal packing for services where chemical resistance, high strength and sliding properties are essential",
				"Unique packing to run at high speed with minimum wear of shaft, exceptional extrusion resistance at high pressure together with high thermal conductivity, low co-efficient of friction",
				"The special aramid fibres at the corners enhance pressure and extrusion resistance of the packing even at higher temperatures"
			],
			download: ""
		},
		{
			id: 102,
			name: "Flax Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/102.jpg",
			features: [
				"The packing is resilient and flexible",
				"A low cost packing for rotary pump"
			],
			download: ""
		},
		{
			id: 103,
			name: "Ramie Fibre Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/103.jpg",
			features: [
				"Superior alternative to both conventional asbestos greased graphited packing and cotton packing",
				"Tough vegetable fibres are gentle on the shaft and prevent scoring",
				"Extends pump bearing life",
				"PTFE lubrication reduces friction"
			],
			download: ""
		},
		{
			id: 104,
			name: "Ramie Fibre Braided Packing + PTFE Dispersion",
			src: "/images/products/jdjones/104.jpg",
			features: [
				"Superior alternative to conventional asbestos greased graphited packing as well as cotton packing",
				"Tough vegetable fibres that are gentle on the shaft, prevent scoring",
				"Extends pump bearing life",
				"PTFE lubrication reduces friction"
			],
			download: ""
		},
		{
			id: 105,
			name: "Ramie Fibre Braided Packing + Graphite Dispersion + Oil",
			src: "/images/products/jdjones/105.jpg",
			features: [
				"Superior alternative to conventional asbestos greased graphite packing as well as cotton packing",
				"PTFE lubrication reduces friction",
				"Tough vegetable fibres are gentle on the shaft, prevent scoring",
				"Extends pump bearing life",
				"PTFE lubrication reduces friction",
				"Presence of graphite improves heat dissipation"
			],
			download: ""
		},
		{
			id: 106,
			name: "PTFE + Carbon Fibre Braided Packing",
			src: "/images/products/jdjones/106.jpg",
			features: [
				"A combination of two different chemical properties makes it unique multi-service packing",
				"Presence of graphite fibre disperses excess heat generation due to friction"
			],
			download: ""
		},
		{
			id: 107,
			name: "Cotton + Grease Braided Packing",
			src: "/images/products/jdjones/107.jpg",
			features: [
				"The packing is resilient and flexible",
				"A low cost packing for rotary pump"
			],
			download: ""
		},
		{
			id: 108,
			name: "Cotton + Grease + Graphite Braided Packing",
			src: "/images/products/jdjones/108.jpg",
			features: [
				"The packing is resilient and flexible",
				"The ideal packing for rotary pump"
			],
			download: ""
		},
		{
			id: 109,
			name:
				"Expanded PTFE Graphited (G-2 Fibre) + Aramid Braided Packing",
			src: "/images/products/jdjones/109.jpg",
			features: [
				"Multiservice hydraulic packing",
				"Tough vegetable fibres that are gentle on the shaft, prevent scoring",
				"Extends pump bearing life",
				"PTFE Lubrication reduces friction"
			],
			download: ""
		},
		{
			id: 110,
			name: "Cotton Fibre Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/110.jpg",
			features: [
				"The packing is resilient and flexible",
				"A low cost packing for rotary pump"
			],
			download: ""
		},
		{
			id: 111,
			name: "Flax Fibre Braided Packing + PTFE Dispersion",
			src: "/images/products/jdjones/111.jpg",
			features: [
				"The packing is resilient and flexible",
				"A low cost packing for rotary pump"
			],
			download: ""
		},
		{
			id: 112,
			name: "PTFE Braided Packing + PTFE Dispersion",
			src: "/images/products/jdjones/112.jpg",
			features: [
				"Manufactured from the purest PTFE yarns to give a soft resilient packing with high dimensional strength, low co-efficient of friction, good chemical resistance and inertness to most media",
				"Special PTFE impregnation, the packing contains no oil",
				"Ideal for valves and slow shaft speed applications",
				"Food Grade available"
			],
			download: ""
		},
		{
			id: 113,
			name: "PTFE Braided Packing Ring + Aramid Fibre",
			src: "/images/products/jdjones/113.jpg",
			features: [
				"The aramid yarn core provides the resilience, recovery and dimensional stability",
				"The lubricated PTFE braiding is also chemically inert and protects the aramid core, providing a very low co-efficient of friction"
			],
			download: ""
		},
		{
			id: 114,
			name: "PTFE Braided Packing + PTFE Dispersion (Food Grade)",
			src: "/images/products/jdjones/114.jpg",
			features: [
				"Manufactured from the purest PTFE yarns to give a soft resilient packing withhigh dimensional strength, low co-efficient of friction, good chemicalresistance and inertness to most media",
				"Special PTFE impregnation, the packing contains no oil",
				"Ideal for valves and slow shaft speed applications",
				"Food Grade certified as per Commission Regulation (EU) No 10/2011 of 14 January 2011 and Article 3 of European Regulation No. 1935/2004 and Regulation (EC) No 1935 / 2004"
			],
			download: ""
		},
		{
			id: 115,
			name: "PTFE Braided Packing Ring + Graphite Fibre",
			src: "/images/products/jdjones/115.jpg",
			features: [
				"The carbon yarn core provides the resilience, recovery and dimensional stability",
				"The lubricated PTFE braiding is also chemically inert and protects the carbon core, providing a very low co-efficient of friction"
			],
			download: ""
		},
		{
			id: 116,
			name: "PTFE Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/116.jpg",
			features: [
				"Manufactured from the purest PTFE yarns to give a soft resilient packing with high dimensional strength, low co-efficient of friction, good chemical resistance and inertness to most media",
				"Special PTFE impregnation and added break-in lubricant enables the packing to perform with less friction Special PTFE impregnation and added break-in lubricant enables the packing to perform with less friction",
				"Ideal for moderate shaft speed applications"
			],
			download: ""
		},
		{
			id: 117,
			name: "PTFE Braided Preformed Ring for Plunger Pump",
			src: "/images/products/jdjones/117.jpg",
			features: [
				"Manufactured from the purest PTFE yarns to give a soft resilient packing with high dimensional strength, low co-efficient of friction, good chemical resistance and inertness to most media",
				"Special PTFE impregnation, the packing contains no oil",
				"Ideal for valves and slow shaft speed applications",
				"Available in pre-pressed ring form"
			],
			download: ""
		},
		{
			id: 118,
			name: "Spun Kynol Braided Packing + PTFE + Oil",
			src: "/images/products/jdjones/118.jpg",
			features: [
				"A new fibre that combines the strength of aramid and chemical resistance of PTFE/graphite",
				"A multipurpose plant wide packing for the pulp and paper industry",
				"A thorough impregnation of PTFE provides low friction operations and a dense packing without any leak paths",
				"A non staining and non abrasive packing, can be used for high speed applications"
			],
			download: ""
		},
		{
			id: 119,
			name: "Spun Kynol Braided Packing + PTFE + Oil + Graphite",
			src: "/images/products/jdjones/119.jpg",
			features: [
				"A new fibre that combines the strength of aramid and chemical resistance of PTFE/graphite",
				"A multipurpose plant wide packing for the pulp and paper industry",
				"A thorough impregnation of PTFE provides low friction operations and a dense packing without any leak paths",
				"Impregnation of graphite reduces shaft wear and ensure heat dissipation",
				"A non-staining and non abrasive packing, can be used for high speed applications"
			],
			download: ""
		},
		{
			id: 121,
			name: "Expanded PTFE Graphited + Aramid Braided Packing",
			src: "/images/products/jdjones/121.jpg",
			features: [
				"Excellent resiliency of the packing ensures less gland adjustment",
				"This packing is immune to chemical reaction resulting in a longer life",
				"Presence of graphite transfers the heat generated at the spindle",
				"The construction ensures toughness and durability of aramid yarn together with reduced shaft wear and better thermal conductivity than pure aramid packing"
			],
			download: ""
		},
		{
			id: 122,
			name: "PTFE Yarn Braided Packing + Aramid Fibre",
			src: "/images/products/jdjones/122.jpg",
			features: [
				"The unsurpassed chemical resistance of pure PTFE combined with the mechanical strength and extrusion resistance of tough and durable aramid",
				"Low co-efficient of friction of PTFE, does not wear shafts or sleeves",
				"An ideal packing for applications requiring both highest chemical resistance and mechanical strength"
			],
			download: ""
		},
		{
			id: 123,
			name: "Expanded PTFE Graphited + Aramid Core Braided Packing",
			src: "/images/products/jdjones/123.jpg",
			features: [
				"A true multi-service packing",
				"Excellent chemical resistance, heat dissipating, sliding velocity, low co-efficient of friction properties of PTFE graphite and the abrasion, pressure resistance and mechanical strength of aramid",
				"A homogenous full yarn with good dimensional and volume stability",
				"A soft supple packing, non-abrasive in nature and gentle on the shaft",
				"Ideal for aggressive chemical fluids operating at high pressures and shaft speeds"
			],
			download: ""
		},
		{
			id: 124,
			name: "PTFE Graphited + Aramid Core Braided Packing + Rubber Core",
			src: "/images/products/jdjones/124.jpg",
			features: [
				"Excellent chemical resistance, heat dissipating, sliding velocity, low co-efficient of friction properties of PTFE graphite and the abrasion, pressure/ extrusion resistance and mechanical strength of aramid",
				"High quality silicon rubber hollow core provides superb memory and resilience, enabling the packing to withstand shaft deflections/vibrations with ease",
				"NA 802SR is a soft supple packing, non-abrasive in nature and gentle on the shaft"
			],
			download: ""
		},
		{
			id: 125,
			name: "n-situ Packing",
			src: "/images/products/jdjones/125.jpg",
			features: [
				"Extremely flexible and malleable ‘mouldable’ type packing that can conform to any shape in the stuffing box, filling all deformities, irregularities and scorings and cutting out all leak paths",
				"Excellent chemical resistance, non-scoring and anti-frictional properties—can be used over a wide range of applications",
				"Manufactured from self-lubricating materials with excellent heat dissipation properties",
				"Provides optimum sealing effect in conjunction with braided gland packings",
				"Can be used over a wide range of cross-sections of the stuffing box, eliminating the need to stock various sizes"
			],
			download: ""
		},
		{
			id: 126,
			name: "Expanded Graphite + Inconel Wire Mesh Braided Packing",
			src: "/images/products/jdjones/126.jpg",
			features: [
				"The most advanced technology valve packing, designed for extreme performance",
				"Ideal for valves handling superheated and saturated steam, hydrocarbons and chemicals valves",
				"Packing exhibits extreme pressure and extrusion resistance, serves as an excellent wiper ring in a valve packing set",
				"The packing exterior is densely impregnated with lubricating agents to reduce stem friction and a corrosion inhibitor to prevent pitting",
				"High purity of graphite: 99.5–99.9%",
				"Nuclear grade upon request"
			],
			download: ""
		},
		{
			id: 127,
			name: "Polyamide Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/127.jpg",
			features: [
				"A new generation packing made from filament composed of synthetic polyamide",
				"Excellent mechanical and temperature resistance enables the packing to ensure trouble free performance",
				"A multipurpose plant wide packing for the pulp and paper industry",
				"A thorough impregnation of PTFE provides low friction operations and a dense packing without any leak paths",
				"A non-staining and non-abrasive packing, significantly reduces wear rate than many other packing"
			],
			download: ""
		},
		{
			id: 128,
			name: "Acrylic Fibre Braided Packing + PTFE Dispersion + Oil",
			src: "/images/products/jdjones/128.jpg",
			features: [
				"A general purpose packing with good chemical resistance and high tensile strength",
				"Thorough impregnation of PTFE provides trouble free start-up and low friction operation",
				"A high density packing good for crystallising media",
				"A filament core provides mechanical strength and dimensional stability",
				"Widely used for knife gate valves",
				"Food Grade certified as per Commission Regulation (EU) No 10/2011 of 14"
			],
			download: ""
		},
		{
			id: 129,
			name: "PTFE V-CUP END RINGS AND BRAIDED INTERMEDIATE RINGS SET",
			src: "/images/products/jdjones/120.jpg",
			features: ["Excellent compressibility"],
			download: ""
		},
		{
			id: 130,
			name: "Resilient Hollow Core Packings",
			src: "/images/products/jdjones/130.jpg",
			features: [
				"The hollow core provides memory, helps the packing retain its original shape and strength"
			],
			download: ""
		},
		{
			id: 131,
			name: "PTFE + Acrylic Braided Packing + Rubber Core",
			src: "/images/products/jdjones/131.jpg",
			features: [
				"Made by spiral wrapping of PTFE tape over a core of elastomer, jacketed with inert polypropylene yarn",
				"Braided structure is spirally wrapped to provide an impermeable barrier to liquids",
				"Final jacketing of polypropylene yarn gives complete protection to the packing and makes it abrasion resistant",
				"The unique core construction enables the packing to be stitched together to form an endless seal, thus preventing the chance of any leakage at the joint"
			],
			download: ""
		}
	],

	Expanded: [
		{
			id: 132,
			name: "NA 600 Joint Sealant Tape",
			src: "/images/products/jdjones/132.jpg",
			features: [
				"A non-staining and non-contaminating sealant for use in pipe flange",
				"A low friction sealant with excellent chemical resistance, even against strong acids and caustics",
				"Wide application in refineries and other industries"
			],
			download: "/documents/jdjones/132.pdf"
		},
		{
			id: 133,
			name: "NA 620 Valve Stem Packing",
			src: "/images/products/jdjones/133.jpg",
			features: [
				"A non-staining and non-contaminating packing for use in valve stems",
				"A low friction packing with a high degree of pliability enabling it to conform to valve stuffing box flaws and irregularities",
				"Excellent chemical resistance even against strong acids and caustics"
			],
			download: "/documents/jdjones/133.pdf"
		}
	],

	Insulation: [
		{
			id: 134,
			name: "Glass Fibre Rope",
			src: "/images/products/jdjones/134.jpg",
			features: [
				"Excellent insulation rope that offers good flexibility, dimensional stability and high tensile strength",
				"Resistance to many acids and alkalis, most bleaches and solvents"
			],
			download: "/documents/jdjones/134.pdf"
		},
		{
			id: 135,
			name: "NA 550 Ceramic Rope",
			src: "/images/products/jdjones/135.jpg",
			features: [
				"The best choice for thermal insulation",
				"Withstands up to 1,260°C",
				"Much better substitute for conventional asbestos ropes, which are not meant for use over 450°C",
				"Manifold service life in comparison to the life than asbestos ropes",
				"Very low loss upon ignition",
				"Saves energy loss, reduces downtimes"
			],
			download: "/documents/jdjones/135.pdf"
		},
		{
			id: 136,
			name: "Ceramic Rope + Graphite Dispersion",
			src: "/images/products/jdjones/136.jpg",
			features: [
				"One of the best choices for thermal insulation",
				"Withstands up to 1,260°C",
				"Much better substitute for conventional asbestos ropes, which are not meant for use over 450°C",
				"Manifold service life in comparison to the life of asbestos rope",
				"Saves energy loss, reduces downtimes",
				"Available in square as well as round cross sections in braided or twisted form"
			],
			download: "/documents/jdjones/136.pdf"
		},
		{
			id: 137,
			name: "Ceramic Braided Rope + Silica Fibre",
			src: "/images/products/jdjones/137.jpg",
			features: [
				"The best choice for thermal insulation",
				"Withstands up to 1260°C",
				"Much better substitute of conventional asbestos rope which is not meant for use over 450°C",
				"Manifold service life in comparison to asbestos rope",
				"Very low loss upon ignition",
				"Saves energy loss, reduces downtimes"
			],
			download: "/documents/jdjones/137.pdf"
		},
		{
			id: 138,
			name: "Silica Fibre Rope",
			src: "/images/products/jdjones/138.jpg",
			features: [
				"The best choice for thermal insulation—withstands up to 1,260°C continuous temperature",
				"Much better substitute to conventional asbestos ropes, which are not meant for use over 450°C",
				"More effective than ceramic and glass rope",
				"High grade silica fibre resists elevated temperature for longer duration without any thermal degradation",
				"Practically unaffected by direct flame, hot blast, molten metal, welding sparks and flux",
				"Zero loss on ignition",
				"Saves energy loss, reduces downtimes"
			],
			download: "/documents/jdjones/138.pdf"
		},
		{
			id: 139,
			name: "NA 555 Ceramic Cloth/Tape",
			src: "/images/products/jdjones/139.jpg",
			features: [
				"Excellent insulation cloth that withstands up to 1,250°C",
				"Much better substitute of conventional asbestos cloth which are not meant for use over 450°C",
				"Manifold service life in comparison to the life of asbestos cloth",
				"Very low loss upon ignition",
				"Available in rolls, thickness of 2 mm, 3 mm and 5 mm etc; width of 1 m",
				"SS wire reinforced rope cloth available upon request"
			],
			download: "/documents/jdjones/139.pdf"
		},
		{
			id: 140,
			name: "Ceramic Cloth + Vermiculite Coating",
			src: "/images/products/jdjones/140.jpg",
			features: [
				"Excellent insulation cloth that withstands up to 1,250°C",
				"Much better substitute of conventional asbestos cloth which are not meant for use over 450°C",
				"Manifold service life in comparison to the life of asbestos cloth",
				"Very low loss upon ignition",
				"Available in rolls, thickness of 2 mm, 3 mm and 5 mm etc; width of 1 m",
				"SS wire reinforced rope cloth available upon request"
			],
			download: "/documents/jdjones/140.pdf"
		},
		{
			id: 141,
			name: "Silica Fibre Oven Cloth",
			src: "/images/products/jdjones/141.jpg",
			features: [
				"High grade silica fibres resist elevated temperatures for longer durations without any thermal degradation",
				"Ultra low loss upon ignition",
				"Practically unaffected by direct flame, hot blast, molten metal, welding sparks and flux",
				"A much better alternative to asbestos, fibre glass and asbestos cloth"
			],
			download: "/documents/jdjones/141.pdf"
		},
		{
			id: 142,
			name: "NA 557V Silica Fibre Cloth",
			src: "/images/products/jdjones/142.jpg",
			features: [
				"High grade silica fibres resist elevated temperatures for longer durations without any thermal degradation",
				"Zero loss upon ignition",
				"Practically unaffected by direct flame, hot blast, molten metal, welding sparks and flux",
				"A much better alternative to asbestos, fibre glass and asbestos cloth",
				"The best insulating cloth available in the market today"
			],
			download: "/documents/jdjones/142.pdf"
		},
		{
			id: 143,
			name: "Glass Fibre Braided Rope",
			src: "/images/products/jdjones/143.jpg",
			features: [
				"Excellent insulation rope that offers good flexibility, dimensional stability and high tensile strength",
				"Resistance to many acids and alkalis, most bleaches and solvents"
			],
			download: "/documents/jdjones/143.pdf"
		},
		{
			id: 144,
			name: "Cloth/Tape",
			src: "/images/products/jdjones/144.jpg",
			features: [
				"Fibre glass woven tape made from fibre glass yarn",
				"Excellent insulation tape that offers good flexibility, dimensional stability and high tensile strength",
				"Resistant to many acids and alkalis, most bleaches and solvents"
			],
			download: "/documents/jdjones/144.pdf"
		},
		{
			id: 145,
			name: "Ceramic Fibre Braided Rope + Rubber Core + Rubber Coating",
			src: "/images/products/jdjones/145.jpg",
			features: [
				"Specially developed as lid seal to hold vacuum within crucible of molten aluminium",
				"Jacketing of ceramic fibre yarn over a hollow resilient rubber core",
				"Surface coated with red colour silicon rubber solution for abrasion resistance",
				"Withstands temperature and compressive loads"
			],
			download: "/documents/jdjones/145.pdf"
		},
		{
			id: 146,
			name: "Silica Fibre + Inconel Wire Mesh Twisted Braided Rope",
			src: "/images/products/jdjones/146.jpg",
			features: [
				"A new generation insulating packing rope made from high grade silica fibres with a resilient core",
				"Silica fibres are practically untouched by elevated temperatures, with no signs of thermal degradation at extreme conditions",
				"The unique ‘soft core’ construction made from special alloy wires provides excellent memory/resilience to the packing rope, making it reusable in certain conditions",
				"Much better alternative to conventional insulating packings such as asbestos, fibre glass, ceramic etc",
				"Lasts longer than any other material"
			],
			download: "/documents/jdjones/146.pdf"
		}
	],

	LowEmission: [
		{
			id: 147,
			name:
				"Expanded Graphite + Inconel Wire Mesh Jacketing + Inconel Wire Reinforced Braided Packing",
			src: "/images/products/jdjones/147.jpg",
			features: [
				"A fantastic plant-wide block valve spool packing",
				"Passed API 589/607 Fire Safe tests from Yarmouth Research and Technology",
				"Passed API 622 FE test with 3 ppm average leakage from Yarmouth Research and Technology",
				"Packing exhibits extreme pressure and extrusion resistance",
				"The packing exterior is densely impregnated with lubricating agents to reduce stem friction and a corrosion inhibitor to prevent pitting",
				"High purity of graphite: 99.5–99.9%",
				"Packing set treated with sacrificial corrosion inhibitor to prevent valve stem corrosion"
			],
			download: "/documents/jdjones/147.pdf"
		},
		{
			id: 148,
			name: "Low Emission Packing Set",
			src: "/images/products/jdjones/148.jpg",
			features: [
				"The most advanced technology valve packing set designed for high pressure application and extreme performance",
				'Certified as per API 622, 3rd edition from Yarmouth Research & Technology, USA 1"x 1.5"x1/4" with 20 ppm average leakage and 0.4375"x 0.6875"x1/8" with 2 ppm average leakage.',
				"Tested at Yarmouth Research and Technology, USA as per API 589/607 Fire Safe test",
				"Packing set made from high quality braided and moulded graphite in a unique configuration",
				"The braided end rings are excellent wiper rings, prevent build up of free graphite/contamination on the valve stem, increase extrusion resistance, pressure resistance and dimensional stability",
				"Packing set treated with a high temperature resistant solid lubricating agent to reduce friction even at elevated temperature"
			],
			download: "/documents/jdjones/148.pdf"
		},
		{
			id: 149,
			name: "Low Emission Packing Set",
			src: "/images/products/jdjones/149.jpg",
			features: [
				"An ‘Ultra’ low emission packing set tested at Yarmouth Research and Technology, USA and passed as per API 622, 2nd edition with 12 ppm average leakage",
				"Qualified as per ISO 15848, Part 1 without any gland adjustment for temperature range -196°C to 400°C and up to Class 1500",
				"Tested at Yarmouth Research and Technology, USA as per API 589/607 Fire Safe test",
				"Developed after extensive developmental process with series of testing at its own state-of-the-art R&D laboratory",
				"Tested for weight loss, material test and leachables at Yarmouth Research and Technology, USA weight loss in oxygen-rich environment at 900˚F is 2.9% and at 1,000˚F is 14.3%"
			],
			download: "/documents/jdjones/149.pdf"
		}
	],

	Lubricant: [
		{
			id: 150,
			name: "Rolgard-PTFE Reinforced Grease",
			src: "/images/products/jdjones/150.jpg",
			features: [
				"The grease is known to be mechanically and chemically stable, providing effective lubrication at temperatures upto 250°C",
				"Excellent for all metal to metal lubrication",
				"Chemically and mechanically stable. Does not soften or harden, unlike ordinary greases",
				"Excellent thermal stability. Resists oil separation and does not migrate from hot surfaces",
				"Withstands a wide temperature range, –5 to +250°C",
				"Excellent shear stability. Remains where originally applied for extended periods",
				"Reduces friction and wear",
				"Cuts wasteful energy consumption",
				"Controls heat build-up. Safeguards your precious equipment",
				"Reduces noise and vibration, especially in large gears",
				"Contains no chlorine which has been known to promote corrosion",
				"Contains no silicone which has been known to cause migration problems",
				"High di-electric strength",
				"Offers greater malleability than graphite, MoS2 and other solid lubricants",
				"Joints treated with Rolgard® are easily dismantled, even after years",
				"Increases life of equipment",
				"Minimises downtime and maintenance costs"
			],
			download: "/documents/jdjones/150.pdf"
		}
	]
};

export const PRAHERPRODUCTS = {
	Valves: [
		{
			id: 151,
			name: "Praher Wafer Check Valve K4 PVC-U",
			src: "/images/products/praher/151.png",
			features: [
				"Media does not contaminate metal parts",
				"Visual position indication",
				"Disc opening up to 85° ",
				"Excellent flow-rates due to a wider opening cross section",
				"Integrated spacer",
				"Mounting between DIN, ANSI, JIS flanges",
				"Flap stopper and body stiffening ribs for increased strength"
			],
			download: "/documents/praher/151.pdf"
		},
		{
			id: 152,
			name: "Praher Wafer Check Valve K6 PVC-U",
			src: "/images/products/praher/152.png",
			features: [
				"Operating temperature up to 60°C",
				"Tight as of max. 0.3 bar counterpressure",
				"Conical sealing surface for highest of requirements and long service life",
				"Back-flushed shaft for prevention of deposits",
				"Cylindrically housed valve shaft for optimal force transmission",
				"Screw centering for DIN2501 PN10 and ANSI class150",
				"Integrated mounting aid with defined breaking point for simple removal",
				"Horizontal and vertical installation"
			],
			download: "/documents/praher/152.pdf"
		},
		{
			id: 153,
			name: "Praher Diaphragm Valve T4 PVC-U Directly Actuated",
			src: "/images/products/praher/153.png",
			features: [
				"Directly actuated",
				"Simple assembly",
				"Control pressure closes and operating pressure opens the valve",
				"Connection G ¼” for direct screw-in of a diaphragm valve or a pneumatic coupling"
			],
			download: "/documents/praher/153.pdf"
		},
		{
			id: 154,
			name: "Praher Diaphragm Valve T4 PVC-U",
			src: "/images/products/praher/154.png",
			features: [
				"Safety hand wheel with locking positions",
				"Visual position indication",
				"Zero dead leg valve",
				"Suitable for slurries and abrasive media",
				"High viscosity or solid content applications",
				"Available with DIN backing flange, ANSI backing flange or fix flange DIN-ANSI-JIS"
			],
			download: "/documents/praher/154.pdf"
		},
		{
			id: 155,
			name: "Praher Diaphragm Valve T4 PVC-U Pneumatic Actuator",
			src: "/images/products/praher/155.png",
			features: [
				"Plastic actuator body",
				"Visual position indication",
				"Insert/outlet stainless steel",
				"NAMUR adaptor for solenoid valve (with adaptation for K122 and K123)",
				"PO-NC (single acting: fail safe close), PO-NO (single acting: fail safe open), PO-DA (double acting)"
			],
			download: "/documents/praher/155.pdf"
		},
		{
			id: 156,
			name: "Praher 3-way Ball Valve S4 PVC-U",
			src: "/images/products/praher/156.png",
			features: [
				"L or T ball",
				"Locking handle with integrated PLS (Praher Labelling System)",
				"Machined ball",
				"Cushion O-Ring for PTFE seat",
				"Double sealed shaft",
				"Simple upgrade to automatic actuation",
				"90° or 180° limitation on request"
			],
			download: "/documents/praher/156.pdf"
		},
		{
			id: 157,
			name: "Praher 3-way Ball Valve S4 PVC-U Electric Actuator Valpes",
			src: "/images/products/praher/157.png",
			features: [
				"L- or T-ball",
				"15-30 V AC (12-48 V DC) or 100-240 V AC (100-350 V DC)",
				"Optical position indication",
				"Manual hand override",
				"7 adjustable limit switches",
				"Electronic torque limitation",
				"Travel time 12 sec",
				"Duty cycle 50%",
				"IP 66 protected",
				"Anti-condensation-system"
			],
			download: "/documents/praher/157.pdf"
		},
		{
			id: 158,
			name: "Praher 3-way Ball Valve S4 PP Pneumatic Actuator",
			src: "/images/products/praher/158.png",
			features: [
				"L or T ball",
				"Aluminium anodised body with aluminium epoxy powder coated end caps",
				"Any installation position",
				"Rotation angle 90°, +/- 10°",
				"Integrated optical position indication"
			],
			download: "/documents/praher/158.pdf"
		},
		{
			id: 159,
			name: "Praher 2-way Ball Valve M1 PVC-U",
			src: "/images/products/praher/159.png",
			features: [
				"Modular design: easy switch between different configurations due to special features and fitting devices",
				"Silicon-free",
				"Buttress thread for perfect fit of the union nut",
				"Integrated bracket for fix point mounting",
				"Integrated fixations for the modular adapter set: one-piece injected valve body",
				"Cushion O-ring for PTFE-seat",
				"Double sealed shaft",
				"Locking handle with integrated PLS (Praher Labelling System)",
				"Version with plastic screws available on request"
			],
			download: "/documents/praher/159.pdf"
		},
		{
			id: 160,
			name: "Praher 2-way Ball Valve M1 PVDF Electric Actuator Valpes",
			src: "/images/products/praher/160.png",
			features: [
				"Silicon-free",
				"Buttress thread for perfect fit of the union nut",
				"Integrated bracket for fix point mounting",
				"4 adjustable limit switches",
				"Position indication",
				"Torque and force limiters",
				"Travel time 6–50 sec",
				"Duty cycle 30%"
			],
			download: "/documents/praher/160.pdf"
		},
		{
			id: 161,
			name: "Praher 2-way Ball Valve M1 PVDF Pneumatic Actuator",
			src: "/images/products/praher/161.png",
			features: [
				"Silicon-free",
				"Buttress thread for perfect fit of the union nut",
				"Integrated bracket for fix point mounting",
				"Aluminium anodised body",
				"Aluminium epoxy powder coated end caps",
				"Any installation position",
				"Rotation angle 90°, +/- 10°",
				"Integrated optical position indication"
			],
			download: "/documents/praher/161.pdf"
		},
		{
			id: 162,
			name: "Praher 2-way Ball Valve M1 PVDF with Position Feedback",
			src: "/images/products/praher/162.png",
			features: [
				"Modular design: Easy switch between different configurations due to special features and fitting devices",
				"Silicon-free",
				"Buttress thread for perfect fit of the union nut",
				"Integrated bracket for fix point mounting",
				"Locking handle with integrated PLS",
				"(Praher Labelling System)",
				"Cushion O-ring for PTFE-seat",
				"Double sealed shaft",
				"Limit switch IP67 mechanical Ag-Ni incl. stainless steel bolts and device plug (on request: mechanical Au, inductive or Namur)",
				"Version with plastic screws available on request"
			],
			download: "/documents/praher/162.pdf"
		},
		{
			id: 163,
			name: "Praher Sampling Ball Valve S4 PVC-U",
			src: "/images/products/praher/163.png",
			features: [
				"Safety locking ring for handle",
				"PTFE ball seat",
				"Connection set (BSP, NPT)",
				"Assembling set"
			],
			download: "/documents/praher/163.pdf"
		},
		{
			id: 164,
			name: "Praher Foot Valve PVC-U",
			src: "/images/products/praher/164.png",
			features: [
				"Modular series concept",
				"Weight-loaded cone",
				"PP strainer"
			],
			download: "/documents/praher/164.pdf"
		},
		{
			id: 165,
			name: "Praher Cone Check Valve S4 CPVC",
			src: "/images/products/praher/165.png",
			features: [
				"Modular series concept",
				"Spring material AISI 316 (V4A) or PTFE coated",
				"Reduces noise and vibrations during in line turbulence"
			],
			download: "/documents/praher/165.pdf"
		},
		{
			id: 166,
			name: "Praher 6-way backwash valve ABS/ASA-GF",
			src: "/images/products/praher/166.png",
			features: [
				"Easy handling",
				"Clear, multilingual and non-smudging laser marking",
				"Optimal flow and pressure loss rates",
				"Low-noise operation",
				"Made of high quality, UV resistant and impact resistant ASA-GF materials",
				"Very durable sealing materials and pressure springs (installed on the valve plate)",
				"Pressure tightness and operation tests according to DIN 3230",
				"Easy installation of a AQUASTAR automatic back wash valve",
				'"Winter position” as release for the sealing system'
			],
			download: "/documents/praher/166.pdf"
		},
		{
			id: 167,
			name: "Praher AQUASTAR® mp6 pos",
			src: "/images/products/praher/167.png",
			features: [
				"Valve body in ABS (MOP 3.5) or ASA-GF (MOP 5)",
				"230 V AC or 24 V AC",
				"Protection class IP65",
				"UV resistant",
				"Manual override",
				"6 Positions – position ‘circulate’ for salt water chlorination",
				"Easy adaptation to all commonly used filters by means of a 1 ½” or 2″ side- or top mount or 3″ side mount"
			],
			download: "/documents/praher/167.pdf"
		},
		{
			id: 168,
			name: "Praher 6-way Backwash Valve AQUASTAR® Easy",
			src: "/images/products/praher/168.png",
			features: [
				"Valve body in ABS (MOP 3.5) and ASA-GF (MOP 5)",
				"100-240 V AC or 24 V AC/DC",
				"Type 1001 (with time switch and time key) or type 4001 (with time switch, time key and additionally electric pressure switch)",
				"Protection class IP65",
				"UV resistant",
				"Manual override",
				"5 positions – position ‘circulate’ for salt water chlorination",
				"Easy adaptation to all commonly used filters by means of a 1 ½” or 2″ side- or top mount"
			],
			download: "/documents/praher/168.pdf"
		},
		{
			id: 170,
			name: "Praher 6-way Backwash Valve AQUASTAR® Comfort",
			src: "/images/products/praher/170.png",
			features: [
				"Valve body in ABS (MOP 3.5) and ASA-GF (MOP 5)",
				"100-240 V AC or 24 V AC/DC",
				"Type 1001 (with time switch) or type 3001 (with digital timer) or type 4001 (with time and pressure switch) or type 6001 (with pressure switch and digital timer)",
				"Types 3501, 4501 and 6501 additionally with filter pump clock",
				"Protection class IP65",
				"UV resistant",
				"Manual override",
				"5 positions – position ‘circulate’ for salt water chlorination",
				"Easy adaptation to all commonly used filters by means of a 1 ½” or 2″ side- or top mount or 3″ side mount"
			],
			download: "/documents/praher/170.pdf"
		},
		{
			id: 171,
			name: "Praher Butterfly Valve K4 PP Electric Actuator Valpes",
			src: "/images/products/praher/171.png",
			features: [
				"Installation without additional adaptor or screws",
				"Manual override",
				"Protection class IP65 resp. IP67",
				"Position indication",
				"Torque and force limiters",
				"Travel time 6–50 sec"
			],
			download: "/documents/praher/171.pdf"
		},
		{
			id: 172,
			name: "Praher Butterfly Valve K4 PVC-U with Hand Lever",
			src: "/images/products/praher/172.png",
			features: [
				"Safety handle with PLS – Praher Labelling System",
				"Ten different locking positions",
				"Double sealed shaft",
				"Encapsulated wetted parts",
				"Integrated brackets for fix point mounting",
				"Simple upgrade to automatic actuation – ISO 5211 flange type (F07, F10)",
				"Mounting between DIN, ANSI, JIS flanges"
			],
			download: "/documents/praher/172.pdf"
		},
		{
			id: 173,
			name: "Praher Butterfly Valve K4 CPVC Pneumatic Actuator",
			src: "/images/products/praher/173.png",
			features: [
				"Aluminium anodised body",
				"Aluminium epoxy powder coated end caps",
				"Any installation position",
				"Rotation angle 90°, +/- 10°",
				"Integrated optical position indication"
			],
			download: "/documents/praher/173.pdf"
		}
	],

	Pipes: [
		{
			id: 174,
			name: "IBG® Bends PVC-U 90°",
			src: "/images/products/praher/174.png",
			features: [
				"Designed for usage of 25 years with media water and 20°C media temperature",
				"Due to: ISO 727, DIN EN ISO 1452 and DIN EN ISO 15493",
				"Optimized for the process of dry installation"
			],
			download: "/documents/praher/174.pdf"
		},
		{
			id: 175,
			name: "IBG® Elbows PVC-U 90° BSP",
			src: "/images/products/praher/175.png",
			features: [
				"Designed for usage of 25 years with media water and 20°C media temperature",
				"Due to: ISO 727, DIN EN ISO 1452, DIN EN ISO 15493 and ISO 7-1",
				"Optimized for the process of dry installation"
			],
			download: "/documents/praher/175.pdf"
		},
		{
			id: 176,
			name: "IBG® Flanges PVC-U Backing Flange",
			src: "/images/products/praher/176.png",
			features: ["DIN"],
			download: "/documents/praher/176.pdf"
		},
		{
			id: 177,
			name: "IBG® Pipe Clips PP Type “A”",
			src: "/images/products/praher/177.png",
			features: [],
			download: "/documents/praher/177.pdf"
		},
		{
			id: 178,
			name: "IBG® Unions PVC-U Metric/BSP Type A Female",
			src: "/images/products/praher/178.png",
			features: [],
			download: "/documents/praher/178.pdf"
		}
	],

	Flow: [
		{
			id: 179,
			name: "Praher Line Strainer S4 PVC-U",
			src: "/images/products/praher/179.png",
			features: [
				"Measuring tube: PA, PSU or PVC",
				"Floats: PVDF",
				"Measuring range: 1,5–60.000 l/h",
				"Inserts with damping feature for float in case of water hammer",
				"PVDF coated stainless steel guide rod for the dimensions DN50 and DN65 to stabilize the float",
				"Double scale for water in percent and l/h",
				"Special adhesive scales for liquids and gas"
			],
			download: "/documents/praher/179.pdf"
		}
	],

	Line: [
		{
			id: 180,
			name: "Praher Line Strainer S4 PVC-U",
			src: "/images/products/praher/180.png",
			features: [
				"Transparent body",
				"PP plastic insert, mesh size 1.8 mm (standard configuration)",
				"Stainless steel screen AISI 304, mesh size 0.5 mm, 0.75 mm or 1 mm",
				"Also suitable as sight glass"
			],
			download: "/documents/praher/180.pdf"
		}
	]
};

export const SUEZPRODUCTS = {
	Boiler: [
		{
			id: 5,
			name: "Internal Boiler Water Treatment Chemicals",
			src: "/images/products/suez/5.jpg",
			features: [
				"Increases iron removal from the steam generator system utilizing the existing blowdown systems ",
				"Reduces future deposition and potentially reduces existing deposit inventory",
				"Improves iron transports through the steam generator ",
				"Reduces the probability of general or localized deposit associated corrosion"
			],
			download: ""
		},
		{
			id: 6,
			name: "Feedwater Treatment Oxygen Scavenger Chemicals",
			src: "/images/products/suez/6.jpg",
			features: [
				"Improves equipment reliability ",
				"Controls dissolved oxygen pitting in boiler feedwater, internal boiler and steam condensate systems ",
				"Reduces maintenance costs associated with dissolved oxygen failures ",
				"Minimizes feedwater and condensate iron and copper levels, reducing loading of these scale-forming corrosion products to the boiler"
			],
			download: ""
		},
		{
			id: 7,
			name: "Steam Condensate Treatment",
			src: "/images/products/suez/7.jpg",
			features: [
				"Reduces condensate corrosion ",
				"Reduces maintenance costs",
				"Improves boiler system reliability ",
				"Reduces the levels of iron and copper in boiler feedwater"
			],
			download: ""
		},
		{
			id: 8,
			name: "TrueSense* Analyze: Boiler Water Analyzer",
			src: "/images/products/suez/8.png",
			features: [
				"Maintaining the highest levels of efficiency, reliability, and output in day to day competitive, performance-based environment.",
				"Increased steam production, increased fuel efficiency, extending the operational life of the boiler through reduced maintenance, and unplanned outages.",
				"Optimized boiler water treatment programs can help reduce water use, energy use, and greenhouse gas emissions.",
				"Enhanced plant safety, system reliability, and availability by protecting critical steam-generating assets from damaging waterside scale formation and corrosion.",
				"Single-box packaging helps simplify installation and commissioning for fast deployment."
			],
			download: ""
		}
	],

	Cooling: [
		{
			id: 9,
			name: "Deposition and Scale Control Chemicals ",
			src: "/images/products/suez/9.jpg",
			features: [
				"Increases unit throughput and heat transfer ",
				"Provides superior evaporative cooling, reduces makeup water costs",
				"Reduces wastewater treatment costs",
				"Cuts power consumption ",
				"Trims maintenance requirements",
				"Maintains high heat transfer rates in systems with enhanced chiller tubes"
			],
			download: ""
		},
		{
			id: 10,
			name: "Microbiological Control Agents",
			src: "/images/products/suez/10.jpg",
			features: [
				"Spectrus BD: to enhance the biocide performance",
				"Spectrus CT: to address macrofouling organisms ",
				"Spectrus DT: detox materials",
				"Spectrus NX: non-oxidizing biocides",
				"Spectrus OX: oxidizing biocides"
			],
			download: ""
		},
		{
			id: 11,
			name: "Cooling Water Corrosion Treatment",
			src: "/images/products/suez/11.jpg",
			features: [
				"Improves productivity and equipment reliability  ",
				"More forgiving chemistry that helps protect assets",
				"Reduces problems from unscheduled shutdowns ",
				"Ability to use alternative water sources",
				"Cleaner equipment leading to lower overall costs and fewer cleanings",
				"Superior protection when compared to any other chemistry available in the market"
			],
			download: ""
		},
		{
			id: 12,
			name: "TrueSense* Cooling Water Technology",
			src: "/images/products/suez/12.jpg",
			features: [
				"Orthophosphate for steel corrosion control",
				"Polymers for the prevention of deposits from mineral scales and dispersion of suspended solids",
				"Free halogen for the cost-effective control of microbiological growth",
				"Reliable onboard filtration that requires minimal maintenance with high suspended solids and high-turbidity waters",
				"Alarming and self-contained diagnostics for fast and effective troubleshooting"
			],
			download: ""
		},
		{
			id: 13,
			name: "OptiSea* for Seawater Cooling Systems",
			src: "/images/products/suez/13.jpg",
			features: [
				"Sateq* for Seawater - Visualize deposition risk with saturation modeling for high ionic strength seawater.",
				"OptiSea Chemistry - Control deposition and solids with new, custom-tailored chemsitry.",
				"TrueSense* MonitAll - Online, real-time deposition monitor and heat exchange simulator.",
				"TrueSense Ready, Set, Go (RSG) - Controller with pH, ORP, and conductivity.",
				"Smart Pumps - Never again question if the chemical feed system is working correctly.",
				"Tank Level Sensors - Supervise and confirm automatically that products were added according to plan.",
				"InSight* - View and track seawater cooling system performance and provide effective alarming based on intensive monitoring, on a proactive basis. "
			],
			download: ""
		}
	],

	Membrane: [
			{
			id: 18,
			name: "RO Membrane Antiscalants",
			src: "/images/products/suez/18.jpg",
			features: [
				"Effectively control scales including silica, calcium carbonate, calcium sulfate, barium sulfate, and strontium sulfate",
				"Compatible with the leading reverse osmosis (RO) and nanofiltration (NF) membranes.",
				"Maintain cleaner membrane surfaces by dispersing particulate foulants.",
				"RO systems can be run at higher recovery rates, which translates to lower operating costs.",
				"Products available with multiple global regulatory approvals.",
				"Non-Phosphorus products available.",
				"Effective over a wide pH range."
			],
			download: ""
		},
		{
			id: 14,
			name: "RO Membrane Cleaning Chemicals",
			src: "/images/products/suez/14.jpg",
			features: [
				"Restore flux rates close to that of new membranes ",
				"Prolong membrane life",
				"Improve recovery and rejection rate percentages",
				"Protect membranes from irreversible fouling"
			],
			download: ""
		},
		{
			id: 15,
			name: "Non-Oxidizing Biological Control Chemicals",
			src: "/images/products/suez/15.jpg",
			features: [
				"Reduces unscheduled downtime and operating costs ",
				"Increases efficiency and reliability of production and supply  ",
				"Maximizes water recovery at high permeate quality ",
				"Extends membrane life and compatible with most membranes ",
				"Effective sanitation of RO and other membrane water treatment systems"
			],
			download: ""
		},
		{
			id: 16,
			name: "Membrane Pretreatment Chemicals",
			src: "/images/products/suez/16.jpg",
			features: [
				"Helps maintain optimum feedwater conditions to and from your membrane system  ",
				"More forgiving chemistry that helps protect assets",
				"Maintains system performance  ",
				"Reduces total downtime ",
				"Protects membranes from oxidizing agents",
				"Decreases particulate loading on RO systems   ",
				"Extends cartridge filter and membranes elements life ",
				"Effective over a wide pH range  ",
				"Minimizes backwash waste  ",
				"Increases resistance-t0-filter breakthrough ",
				"Improves overall operation of filtration systems and clarifiers  ",
				"Will not add organic material or carbon fines to the feedwater or to the Ro/UF systems  ",
				"Improves overall operation of filtration systems and clarifiers "
			],
			download: ""
		},
		{
			id: 17,
			name: "Aqualead* Water Conditioning",
			src: "/images/products/suez/17.jpg",
			features: [
				"sales team: Our teams monitor your facilities on a daily basis through analyses, water and product consumption reports; providing you with recommendations to ensure the efficacy of the treatments put in place. They provide close monitoring and remain your main contact to guarantee a true, long-term partnership.",
				"Our technical teams support you in all your requests, and in particular for  chemical and microbiological analyses in our laboratory, on-site audits, risk analysis and technical recommendations.",
				"Our training centre runs training modules all over Europe from beginners to experts. Conducted on our premises or directly at your site, our training courses have flexible content to enable the creation of bespoke sessions.",
				"Our innovation division is responsible for developing new technology and products. To do this, our teams have dedicated pilot units and ensure constant technological watch to consistently create solutions that meet your needs.s"
			],
			download: ""
		}
	],

	Wastewater: [
		{
			id: 19,
			name: "Antifoaming & Defoaming Agents",
			src: "/images/products/suez/19.jpg",
			features: [
				"Eliminates troublesome foaming conditions in a variety of application areas.",
				"Protects expensive process equipment where foaming can lead to production and asset threatening issues. ",
				"Ensures manufacturing production downtime due to foaming is minimize or eliminated",
				"Helps in maintaining regulatory compliance. ",
				"Effective in many wastewater systems and industrial processes ",
				"FoamTrol programs are stable over a wide temperature range. ",
				"Product handling – long stability (of most products) and convenient packaging ",
				"Simplicity of application: can be fed with automatic feed systems in many applications) "
			],
			download: ""
		},
		{
			id: 20,
			name: "Wastewater Bioaugmentation Chemicals",
			src: "/images/products/suez/20.jpg",
			features: [
				"Enhances microbial populations and degradation or organics",
				"Provides micronutrients and stimulates for growth under a wide array of conditions",
				"Lower treatment costs for reuse ",
				"Improves community image and minimizes the potential for fines and/or extra compliance costs from regulatory authorities "
			],
			download: ""
		},
		{
			id: 21,
			name: "Coagulants and Flocculants for Water Treatment",
			src: "/images/products/suez/21.jpg",
			features: [
				"Reduces fouling by solids, organics, microbiological materials and colloids",
				"Increases settling rates",
				"Improves filter performance",
				"Reduces sludge volume",
				"Improves efficiency in boiler and cooling systems",
				"Enhances performance of scale inhibitors",
				"Improves process operations",
				"Produces cleaner, safer drinking water"
			],
			download: ""
		},
		{
			id: 22,
			name: "Heavy Metals Removal",
			src: "/images/products/suez/22.jpg",
			features: [
				"Remove heavy metals from wastewater over a wide pH range",
				"Reduce treatment costs",
				"Helps meet regulatory requirements",
				"Reduces potential for future remediation",
				"Improves community health",
				"Enables wastewater recycling for plant processes",
				"Can be applied during normal treatment process or as a polishing agent for industrial wastewater treatment"
			],
			download: ""
		},
		{
			id: 23,
			name: "Wastewater Odor Control Chemicals",
			src: "/images/products/suez/23.jpg",
			features: [
				"Control of sulfide and nuisance odors",
				"Improvement of ambient air compliance to H2S and nuisance odor criteria",
				"Elimination of unpleasant and unsafe work environment associated with hydrogen sulfide",
				"Improvement of environmental image and public relations with surrounding communities ",
				"Maintenance of equipment integrity and prevention of corrosion related maintenance cost due to H2S influenced corrosion",
				"Control of odors without creating additional sludge in systems being treated",
				"Availability of technical and engineering support to provide operating and monitoring recommendations "
			],
			download: ""
		},
		{
			id: 24,
			name: "Wastewater Selenium Removal",
			src: "/images/products/suez/24.jpg",
			features: [
				"Proven solution to consistently meet discharge limits in EPA’s proposed ELG for FGD wastewater",
				"Effective nitrate removal",
				"Effective mercury and arsenic polishing",
				"Non-hazardous waste solids easily processed by the Phys-Chem solids handling system",
				"Direct discharge without pre or post treatment",
				"Proven performance at TDS concentrations greater than 35,000 mg/l"
			],
			download: ""
		}
	],

	Industrial: [
		{
			id: 25,
			name: "FuelTreatment Chemicals",
			src: "/images/products/suez/25.jpg",
			features: [
				"Reduces operating and fuel costs",
				"Improves system reliability",
				"Helps ensure compliance with emission regulations",
				"Reduces the chance of boiler system corrosion"
			],
			download: ""
		},
		{
			id: 26,
			name: "FoodPro* Soft Water Corrosion Inhibitors",
			src: "/images/products/suez/26.jpg",
			features: [
				"Specifically formulated for low hardness applications",
				"Controls soft water corrosion, deposition, and staining",
				"Prevents corrosion and extends equipment life",
				"Produces bright and shiny packs",
				"Convenient liquid product",
				"Free of metal ions and inorganic phosphates"
			],
			download: ""
		}
	],

	Dust: [
		{
			id: 27,
			name: "Dust Control Programs ",
			src: "/images/products/suez/27.jpg",
			features: [
				"Provides control of fugitive emissions while minimizing moisture addition",
				"Enhances wetting of dust particles",
				"Reduces maintenance and housekeeping costs",
				"Improve working conditions "
			],
			download: ""
		},
		{
			id: 28,
			name: "Coal Dust Suppression",
			src: "/images/products/suez/28.jpg",
			features: [
				"Reduces dusting during loading, unloading, stack-out and reclamation",
				"Reduces in-transit coal losses from rail cars – may eliminate need for car topper treatments",
				"Retards the oxidation and weathering of low rank coals, such as Powder River Basin (PRB) coals",
				"Significantly reduces hot spots, smokers and spontaneous combustion at generating stations and coal terminals",
				"Reduces BTU or calorific losses during storage at coal yards and terminals"
			],
			download: ""
		},
		{
			id: 29,
			name: "Dust Control for the Power Industry",
			src: "/images/products/suez/29.jpg",
			features: [
				"Reduced dusting from barge or railcar unloading to the plant building.",
				"Significant reduction of coal oxidation – significantly reduced hot spots, smokers and spontaneous combustion reported at storage piles and silos.",
				"Effective dust suppression with low moisture addition to the coal and other fuels, translating to reduced calorific value penalties.",
				"Significant reduction of coal flow problems, resulting from wet coal conditions."
			],
			download: ""
		}
	],

	Hydrocarbon: [
		{
			id: 30,
			name: "Emulsion Breaker Solutions",
			src: "/images/products/suez/30.jpg",
			features: [
				"Maximized throughput and increased production",
				"Improved product quality and operational reliability",
				"Lowered overall cost and energy consumption",
				"Improved environmental, health, and safety regulatory compliance",
				"Increases profit resulting from higher ash removal efficiency"
			],
			download: ""
		},
		{
			id: 31,
			name: "SpecAid Finished Fuels Treatment",
			src: "/images/products/suez/31.jpg",
			features: [
				"Helps refiners and marketers cost effectively meet fuel specifications",
				"Ensures proper fuel handling and performance characteristics",
				"State-of-the-art gasoline, diesel, aviation fuel, blend component, biofuel and feed stock additives",
				"Customized feedback and system recommendations from SUEZ treatment specialists"
			],
			download: ""
		},
		{
			id: 32,
			name: "Hydrogen Sulfide (H2S) Scavenger Solutions",
			src: "/images/products/suez/32.jpg",
			features: [
				"Control of sulfides and nuisance odors",
				"Improvement ambient air compliance to H2S and nuisance odor criteria",
				"Elimination of unpleasant and unsafe work environment associated with hydrogen sulfide",
				"Improvement of environmental image and public relations with surrounding communities",
				"Maintenance of equipment integrity and prevention of corrosion-related maintenance costs due to H2S-influenced corrosion",
				"Control of odors without creating additional sludge in systems being treated",
				"Availability of technical and engineering support to provide operating and monitoring recommendations that complement chemical solutions for an overall cost-effective and robust solution"
			],
			download: ""
		},
		{
			id: 33,
			name: "Antifoulant Chemical Solutions",
			src: "/images/products/suez/33.jpg",
			features: [
				"Maximizes throughput and increases production",
				"Improves product quality",
				"Improves overall equipment reliability",
				"Reduces equipment cleaning and replacement costs",
				"Lowers overall energy costs",
				"Lowers waste disposal costs",
				"Improves worker safety and environmental concerns"
			],
			download: ""
		},
		{
			id: 34,
			name: "Industrial Corrosion Inhibitors ",
			src: "/images/products/suez/34.png",
			features: [
				"Reduce equipment cleaning and replacement cost",
				"Maximize throughput and increase production",
				"Improve product quality and operational reliability",
				"Lower overall cost and energy consumption",
				"Lower waste disposal",
				"Improve worker safety and compliance with environmental, health, and safety regulations",
				"Increase profit resulting from higher ash removal efficiency"
			],
			download: ""
		}
	],

	Chemical: [
		{
			id: 35,
			name: "Chemical Solutions for the Ethylene Industry",
			src: "/images/products/suez/35.jpg",
			features: [
				"Reduce ethylene losses and avoid throughput reductions due to fouling and corrosion",
				"Increase energy savings",
				"Increase the lifespan of your equipment",
				"Reduce maintenance costs",
				"Reduce equipment cleaning frequency"
			],
			download: ""
		},
		{
			id: 36,
			name: "Chemical Solutions for the Styrene Industry",
			src: "/images/products/suez/36.jpg",
			features: [
				"Better control of polymerization",
				"Longer sustainable run length",
				"Reduction in retarder consumption",
				"Better control of the polydivinyl benzene formation in the styrene monomer tower",
				"lower overall cost",
				"Elimination of NOx generation "
			],
			download: ""
		}
	]
};

export const PUREWATERPRODUCTS = {
	test1: [],

	test2: []
};
