import React from "react";
import Sidebar from "react-sidebar";
import "../assets/stylesheets/sidebar.css";
import { Link } from "react-router-dom";

class Sidemenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: this.props.open
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  render() {
    return (
      <Sidebar
        sidebar={
          <div className="parent">
            <div className="sidebarTitle">
              <b>Companies</b>
            </div>
            <div className="sidebarText">
              <Link
                to="/products/eastman"
                onClick={() => this.onSetSidebarOpen(false)}
              >
                Eastman
              </Link>
            </div>
            <div className="sidebarText">
              <Link
                to="/products/suez"
                onClick={() => this.onSetSidebarOpen(false)}
              >
                Suez
              </Link>
            </div>
            <div className="sidebarText">
              <Link
                to="/products/praher"
                onClick={() => this.onSetSidebarOpen(false)}
              >
                Praher
              </Link>
            </div>
            <div className="sidebarText">
              <Link
                to="/products/jd-jones"
                onClick={() => this.onSetSidebarOpen(false)}
              >
                JD Jones
              </Link>
            </div>
          </div>
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={{
          sidebar: {
            background: "white",
            position: "fixed",
            zIndex: 7
          },
          overlay: {
            zIndex: 4
          }
        }}
      >
        <button onClick={() => this.onSetSidebarOpen(true)} style={{cursor: 'pointer'}}>
          FILTER BY COMPANY
        </button>
      </Sidebar>
    );
  }
}

export default Sidemenu;
