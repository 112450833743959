import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Landing from './components/Landing.js';
import ProductsIndex from './components/Products/index.js';
import Inquiry from './components/Inquiry.js';
import Eastman from './components/Products/Eastman/Eastman.js';
import Suez from './components/Products/Suez/Suez.js';
import Products from './components/Products/Eastman/Products/Products.js';
import Boiler from './components/Products/Suez/Products/Boiler.js';
import Chemical from './components/Products/Suez/Products/Chemical.js';
import Cooling from './components/Products/Suez/Products/Cooling.js';
import Dust from './components/Products/Suez/Products/Dust.js';
import Hydrocarbon from './components/Products/Suez/Products/Hydrocarbon.js';
import Industrial from './components/Products/Suez/Products/Industrial.js';
import Membrane from './components/Products/Suez/Products/Membrane.js';
import Wastewater from './components/Products/Suez/Products/Wastewater.js';
import Documents from './components/Products/Suez/documents.js';
import Jones from './components/Products/Jones/Jones.js';
import Compression from './components/Products/Jones/Products/Compression.js';
import Expanded from './components/Products/Jones/Products/Expanded.js';
import Insulation from './components/Products/Jones/Products/Insulation.js';
import LowEmission from './components/Products/Jones/Products/LowEmission.js';
import Lubricant from './components/Products/Jones/Products/Lubricant.js';
import Praher from './components/Products/Praher/Praher.js';
import Valves from './components/Products/Praher/Products/Valves.js';
import Line from './components/Products/Praher/Products/Line.js';
import Pipes from './components/Products/Praher/Products/Pipes.js';
import Flow from './components/Products/Praher/Products/Flow.js';



class Routes extends Component {
  render() {
	return (
	<Switch>
	<Route exact path='/' component={ Landing }/>
    <Route exact path='/products' component={ ProductsIndex }/>
    <Route exact path='/products/eastman' component={ Eastman }/>
    <Route exact path='/products/eastman/all-products' component={ Products }/>
    <Route exact path='/products/suez' component={ Suez }/>
    <Route exact path='/products/suez/boiler-water-treatment-chemicals' component={ Boiler }/>
    <Route exact path='/products/suez/cooling-water-treatment' component={ Cooling }/>
    <Route exact path='/products/suez/membrane-chemicals' component={ Membrane }/>
    <Route exact path='/products/suez/wastewater-treatment-chemicals' component={ Wastewater }/>
    <Route exact path='/products/suez/industrial-process-treatments' component={ Industrial }/>
    <Route exact path='/products/suez/dust-control-and-material-handling' component={ Dust }/>
    <Route exact path='/products/suez/chemical-processing-treatment' component={ Chemical }/>
    <Route exact path='/products/suez/hydrocarbon-processing-treatment' component={ Hydrocarbon }/>
    <Route exact path='/suez-documents' component={ Documents }/>
    <Route exact path='/products/jd-jones' component={ Jones }/>
    <Route exact path='/products/jd-jones/compression-packing' component={ Compression }/>
    <Route exact path='/products/jd-jones/expanded-ptfe-products' component={ Expanded }/>
    <Route exact path='/products/jd-jones/insulation' component={ Insulation }/>
    <Route exact path='/products/jd-jones/speciality-lubricant' component={ Lubricant }/>
    <Route exact path='/products/jd-jones/low-emission-packing' component={ LowEmission }/>
    <Route exact path='/products/praher' component={ Praher }/>
    <Route exact path='/products/praher/valves' component={ Valves }/>
    <Route exact path='/products/praher/pipe-and-fittings' component={ Pipes }/>
    <Route exact path='/products/praher/line-strainer' component={ Line }/>
    <Route exact path='/products/praher/flow-meter' component={ Flow }/>
    <Route exact path='/inquiry/' component={ Inquiry }/>
	</Switch>
	);
  }
}

export default Routes;
