import { ADD, REMOVE } from "../../actions/cart"


var initialState = {
	products: [],
}

function Cart(state = initialState, action) {

	console.log(state)

	switch (action.type) {
		case ADD:
			var products = [...state.products]
			var exists = 0
			for( var i = 0; i <= products.length-1; i++){ 
			   if ( products[i].id === action.item.id) {
					products[i].count = products[i].count + 1;
					exists = 1; 
					break;

			   }
			}
			if(exists === 0){
				var new_item = Object.assign({}, action.item);
				new_item['count'] = 1;
				console.log('new itemmm')
				console.log(new_item)

				return { 
					products: [...state.products,new_item],
				}
			}

			else{

				return { 
					products: products
				}

			}


		case REMOVE:
			var products = [...state.products]
			var count = state.count
			for( var i = 0; i <= products.length-1; i++){ 
			   if ( products[i].id === action.item.id) {
					products[i].count = products[i].count - 1; 
					break;

			   }
			}

			return { 
				products: products
			}

		default:
			return state;

	}


}

export default Cart;