import React, { Component } from "react";
import { connect } from "react-redux";
import "../assets/stylesheets/inquiry.css";
import { Link } from "react-router-dom";
import Header from "../common/Header.js";
import Footer from "../common/Footer.js";
import valve from "../assets/images/valve.png";
import plus from "../assets/images/plus.svg";
import minus from "../assets/images/minus.svg";
import {
  addToCart,
  removeFromCart
} from "../_Redux/ActionCreators/CartActionCreator";
import {
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";

class Inquiry extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    pincode: "",
    products: false,
    quantity: []
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handlePhoneChange = e => {
    this.setState({ phone: e.target.value });
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handlePincodeChange = e => {
    this.setState({ pincode: e.target.value });
  };

  addItem = (id, name, src) => {
    this.props.addItem({ id: id, name: name, src: src });
  };

  removeItem = (id, name, src, count) => {
    var confirm = true;

    if (count === 1) {
      confirm = window.confirm(
        "Are you sure you want to remove this item from your inquiry cart??"
      );
    }

    if (confirm) {
      this.props.removeItem({ id: id, name: name, src: src });
    }
  };

  componentDidMount = () => {
    var flag = 0
    for(var i=0; i < this.props.products.length; i++){
      if(this.props.products[i].count >0){
        flag = 1
        if(!this.state.products){
          this.setState({products: true})
        }
        break;
      }

    }

    if(flag === 0 && this.state.products){
      this.setState({products: false})
    }
  }

  componentDidUpdate = () => {
    var flag = 0
    for(var i=0; i < this.props.products.length; i++){
      if(this.props.products[i].count >0){
        flag = 1
        if(!this.state.products){
          this.setState({products: true})
        }
        break;
      }

    }

    if(flag === 0 && this.state.products){
      this.setState({products: false})
    }
  }

  render() {
    return (
      <div>
        <Header />
        { this.state.products &&
          <div className="sectionHeader">
            <div className="headerSection">
              <hr className="line"></hr>
            </div>
            <div className="headerSectionMiddle">
              <text className="sectionTitle">
                EDIT <b>INQUIRY</b>
              </text>
            </div>
            <div className="headerSection">
              <hr className="line"></hr>
            </div>
          </div>
        }
        {this.props.products.map(product => {
          return product.count > 0 ? (
            <div className="inquiryItems">
              <div className="itemName">
                <div className="inquiryImage">
                  <img className="itemImage" alt="logo" src={product.src}></img>
                  <text className="inquiryTextBold">
                    {product.name}
                  </text>
                </div>
              </div>
              <div className="itemNumber">
                <button className="inquiryButton">
                  <img
                    className="icon"
                    alt="logo"
                    onClick={() => {
                      this.addItem(product.id, product.name, product.src);
                    }}
                    src={plus}
                  ></img>
                </button>
                <text className="number">{product.count}</text>
                <button className="inquiryButton">
                  <img
                    className="icon"
                    alt="logo"
                    onClick={() => {
                      this.removeItem(
                        product.id,
                        product.name,
                        product.src,
                        product.count
                      );
                    }}
                    src={minus}
                  ></img>
                </button>
              </div>
            </div>
          ) : (
            ""
          );
        })}

        <div className="sectionHeader">
          <div className="headerSection">
            <hr className="line"></hr>
          </div>
          <div className="headerSectionMiddle">
            <text className="sectionTitle">
              SUBMIT <b>INQUIRY</b>
            </text>
          </div>
          <div className="headerSection">
            <hr className="line"></hr>
          </div>
        </div>
        <div className="inquiryForm">
          <span className="formTittle">Request Confirmation Form</span>
          <Form
            className="formContainer"
            action="https://formspree.io/marketing@purewaterindia.com"
            method="POST"
          >
            <FormGroup style={{ marginBottom: "3vw" }}>
              <Input
                className="input"
                type="text"
                name="name"
                placeholder="Full Name*"
                onChange={this.handleNameChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: "3vw" }}>
              <Input
                className="input"
                type="number*"
                name="phone"
                placeholder="Phone Number*"
                onChange={this.handlePhoneChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: "3vw" }}>
              <Input
                className="input"
                type="email"
                name="email"
                placeholder="Email*"
                onChange={this.handleEmailChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: "3vw" }}>
              <Input
                className="input"
                type="text"
                name="pincode"
                placeholder="Pincode*"
                onChange={this.handlePincodeChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: "3vw" }}>
              <Input
                style={{ display: "none" }}
                name="Products Requested"
                type="text"
                value={this.props.products.map(function(data, idx) {
                  return data.name;
                })}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: "3vw", width: "70%" }}>
              <span className="info">
                We will get in touch with you within 24 hours. In case of any
                queries, please contact +91 22-24175662
              </span>
            </FormGroup>
            <Button
              className="formButton"
              disabled={
                !this.state.name ||
                !this.state.phone ||
                !this.state.email ||
                !this.state.pincode
              }
            >
              Request Order
            </Button>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ products }) {
  return { products };
}

function mapDispatchToProps(dispatch) {
  return {
    addItem: item => {
      dispatch(addToCart(item));
    },
    removeItem: item => {
      dispatch(removeFromCart(item));
    }
  };
}

Inquiry = connect(
  mapStateToProps,
  mapDispatchToProps
)(Inquiry);
export default Inquiry;
