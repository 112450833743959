import React, { Component } from "react";
import "../assets/stylesheets/landing.css";
import { Link } from "react-router-dom";
import Header from "../common/Header.js";
import Footer from "../common/Footer.js";
import background from "../assets/images/background.png";
import appuser from "../assets/images/appuser.png";
import woman from "../assets/images/woman.png";
import handshake from "../assets/images/handshake.png";
import eastman from "../assets/images/eastman.png";
import praher from "../assets/images/praher.png";
import jdjones from "../assets/images/jdjones.png";
import suez from "../assets/images/suez.jpg";
import factory from "../assets/images/factory.jpg";

class Landing extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="section1">
          <text className="title"> Water & Process Treatment Solutions</text>
          <text className="subTitle">
            {" "}
            Our experts partner with customers around the world to provide
            solutions for the toughest industrial water and process challenges.
          </text>
          <Link to="/products" className="CTA">
            VIEW PRODUCTS
          </Link>
        </div>
        <div className="sectionLarge">
          <div className="sectionHeader">
            <div className="headerSection">
              <hr className="line"></hr>
            </div>
            <div className="headerSectionMiddle">
              <text className="sectionTitle">
                OUR <b>VISION</b>
              </text>
            </div>
            <div className="headerSection">
              <hr className="line"></hr>
            </div>
          </div>
          <div className="sectionContent">
            <div className="featureBox">
              <div className="imageBox">
                <img className="featureImage" alt="logo" src={woman}></img>
              </div>
              <div className="textBoxTilt">
                <text className="darkHeading">Customer Support</text>
                <text className="darkText">
                  To service our customers to the fullest, ensuring a pleasant
                  working experience for all our employees and associates and
                  making the world a better place to be in.
                </text>
              </div>
            </div>
            <div className="featureBox">
              <div className="imageBox">
                <img className="featureImage" alt="logo" src={handshake}></img>
              </div>
              <div className="textBox">
                <text className="darkHeading">Excellence</text>
                <text className="darkText">
                  In the products we offer, in the logistical support we
                  provide, the service which we render and the new products we
                  introduce.
                </text>
              </div>
            </div>
            <div className="featureBox">
              <div className="imageBox">
                <img className="featureImage" alt="logo" src={appuser}></img>
              </div>
              <div className="textBox">
                <text className="darkHeading">Inquire Online</text>
                <text className="darkText">
                  View our list of products at your convinience and add them to
                  your inquiry list. We will get back to you shortly !{" "}
                </text>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionSmall">
          <div className="sectionSmallHeader">
            <div className="headerSection">
              <hr className="line"></hr>
            </div>
            <div className="headerSectionMiddle">
              <text className="sectionTitle">
                OUR <b>PARTNERS</b>
              </text>
            </div>
            <div className="headerSection">
              <hr className="line"></hr>
            </div>
          </div>
          <div className="sectionSmallContent">
            <div className="divrow">
              <div className="divcolumn">
                <a href="https://www.suez.com/en" target="_blank">
                  <img className="logoImage" alt="logo" src={suez}></img>
                </a>
              </div>
              <div className="divcolumn">
                <a href="https://www.eastman.com/Pages/Home.aspx" target="_blank">
                  <img className="logoImage" alt="logo" src={eastman}></img>
                </a>
              </div>
            </div>
            <div className="divrow">
              <div className="divcolumn">
                <a href="https://www.praher-plastics.com/" target="_blank">
                  <img className="logoImage" alt="logo" src={praher}></img>
                </a>
              </div>
              <div className="divcolumn">
                <a href="http://www.jdjones.com/" target="_blank">
                  <img className="logoImage" alt="logo" src={jdjones}></img>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionSmall">
          <div className="sectionSmallHeader">
            <div className="headerSection">
              <hr className="line"></hr>
            </div>
            <div className="headerSectionMiddle">
              <text className="sectionTitle">
                ABOUT <b>US</b>
              </text>
            </div>
            <div className="headerSection">
              <hr className="line"></hr>
            </div>
          </div>
          <div className={"sectionSmallContent columnView"}>
            <div className="divrow">
              <text className="darkText">
                "We at <b>PURE WATER</b> started the journey into marketing of
                industrial products related to Water Treatment, Heating Fluids
                and Packing solutions in the year 1998. Located in Mumbai, the
                commercial capital of the country, in the last 20 years we have
                successfully forged strong relationships with more than 300
                customers spread over the length and breadth of the country. It
                has always been our endeavour to offer the best products in
                their category and with pride we can say that our principles are
                the industry leaders and trail blazers in the products they
                manufacture and market."
              </text>
            </div>
            <div className="divrow">
              <img className="aboutImg" alt="logo" src={factory}></img>
              <Link className="CTAdark" to='/products'>
                VIEW PRODUCTS
              </Link>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Landing;
