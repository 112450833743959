import React, { Component } from 'react';
import Header from '../../../../common/Header.js';
import Footer from '../../../../common/Footer.js';
import CategoryCard from '../../../../common/CategoryCard.js';
import FeatureCard from '../../../../common/FeatureCard.js';
import Sidemenu from '../../../../common/Sidebar.js';
import '../../../../assets/stylesheets/mainProducts.css';
import { PRAHERPRODUCTS } from '../../../../data/products';

class Flow extends Component {
  render() {
    return (
      <div>
          <Header/>
          <div className="sectionHeader">
              <div className="headerSection">
                <hr className="line"></hr>
              </div>
              <div className="headerSectionMiddle">
                <text className="sectionTitle">OUR <b>PRODUCTS</b></text>
              </div>
              <div className="headerSection">
                <hr className="line"></hr>
              </div>
          </div>
          <div className="productContent">
            <Sidemenu open={false} />
            {

              PRAHERPRODUCTS.Flow.map((product) => {
                return(
                  <FeatureCard
                    src={product.src}
                    name={product.name}
                    id={product.id}
                    features={product.features}
                    download={product.download}
                  />
                )
              })
            }
          </div>
          <Footer/>
      </div>
    );
  }
}

export default Flow;