import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../common/Header.js";
import Footer from "../../common/Footer.js";
import Sidemenu from "../../common/Sidebar.js";
import eastman from "../../assets/images/eastman.png";
import praher from "../../assets/images/praher.png";
import jdjones from "../../assets/images/jdjones.png";
import suez from "../../assets/images/suez.jpg";
import factory from "../../assets/images/factory.jpg";
import "../../assets/stylesheets/landing.css";

class ProductsIndex extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="sectionHeader">
          <div className="headerSection">
            <hr className="line"></hr>
          </div>
          <div className="headerSectionMiddle">
            <text className="sectionTitle">
              SELECT <b> COMPANY</b>
            </text>
          </div>
          <div className="headerSection">
            <hr className="line"></hr>
          </div>
        </div>
        <div className="productContent">
          <div className="sectionSmallContent">
            <div className="divrow">
              <div className="divcolumn">
                <Link to="/products/suez">
                  <img className="logoImage" alt="logo" src={suez}></img>
                </Link>
                <span className="subContext">
                  -Experts in water and waste management.
                </span>
              </div>
              <div className="divcolumn">
                <Link to="/products/eastman">
                  <img className="logoImage" alt="logo" src={eastman}></img>
                </Link>
                <span className="subContext">
                  -Advanced materials and specialty additives company.
                </span>
              </div>
            </div>
            <div className="divrow">
              <div className="divcolumn">
                <Link to="/products/praher">
                  <img className="logoImage" alt="logo" src={praher}></img>
                </Link>
                <span className="subContext">
                  -Producers of valves, fittings and pipes made of PVC, PP and PVDF.
                </span>
              </div>
              <div className="divcolumn">
                <Link to="/products/jd-jones">
                  <img className="logoImage" alt="logo" src={jdjones}></img>
                </Link>
                <span className="subContext">
                  -Manufacturer and supplier of Fluid Sealing Products.
                </span>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ProductsIndex;
