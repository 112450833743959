import React, { Component } from "react";
import "../assets/stylesheets/categoryCard.css";
import valvescover from "../assets/images/valvescover.jpg";
import { Link } from "react-router-dom";

const CategoryCard = props => {
  var joined_name = props.name.replace(/\s+/g, "-").toLowerCase();
  return (
    <Link
      to={"/products/" + props.company + "/" + joined_name}
      className="ImageCat"
    >
      <div className="cardBoxCat">
        <div className="cardContentCat">
          <text className="text">{props.name}</text>
        </div>
        <div className="ImageCat">
          <img className="categoryImage" alt="logo" src={props.src}></img>
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
