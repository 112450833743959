import React, { Component } from "react";
import "../assets/stylesheets/footer.css";
import { Container, Row } from "reactstrap";
class Footer extends Component {
  render() {
    return (
      <div className="footerContainer" id="footer">
        <div className="contentSmall">
          <text className="heading"> Contact us </text>
        </div>
        <div className="content">
          <div className="contentInner">
            <text className="subHeading">Address</text>
            <text className="text">Pure Water Management Pvt. Ltd.</text>
            <text className="text">A/331, Antop Hill Warehousing Complex,</text>
            <text className="text">VIT College Road, Wadala East,</text>
            <text className="text">Mumbai 400037, INDIA</text>
          </div>

          <div className="contentInner">
            <text className="subHeading"> Email Address </text>
            <text className="text"> info@purewaterindia.com </text>
            <text className="subHeading"> Phone Number </text>
            <text className="text"> Tel : 91-22 -24175662 / 24175663 </text>
            <text className="text"> Fax : 91-22 -24175660 </text>
          </div>
        </div>
        <div className="contentSmall">
          <text className="text">
            {" "}
            Copyright © 2019 Pure Water Management Pvt. Ltd. All Right Reserved.{" "}
          </text>
        </div>
      </div>
    );
  }
}

export default Footer;
